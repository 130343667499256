<template>
  <div class="input-root" :style="getRootStyle">
    <div class="form-label labelText" v-if="label">
      {{ label }}
    </div>
    <input
      v-if="type !== 'file'"
      :class="['form-control', { invalidInput: is_invalid ? true : false }]"
      :type="type"
      :label="label"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model.trim="inputfield"
      :style="cus_style"
      :readonly="readOnly"
      :min="min"
    />
    <div v-if="type === 'file'" style="position: relative">
      <input
        :class="[
          'form-control',
          'select-file-input',
          { invalidInput: is_invalid ? true : false },
        ]"
        :label="label"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        style="cursor: pointer; padding: 10px 35px 10px 10px"
        :style="cus_style"
        :readonly="true"
        @click.stop="selectFile"
      />
      <input
        style="display: none"
        type="file"
        :accept="accept"
        ref="select-file-input"
        @change="fileChange"
      />
      <div style="position: absolute; top: 10px; right: 10px">
        <img src="/images/upload-file-icon.png" alt="upload-file-icon" />
      </div>
    </div>
    <div class="helper_text" :style="helper_text_style" v-if="helper_text">
      {{ helper_text }}
    </div>
  </div>
</template>

<script>
export default {
  emits: ["change-value"],
  props: [
    "type",
    "label",
    "placeholder",
    "is_invalid",
    "helper_text",
    "helper_text_style",
    "field",
    "value",
    "cus_style",
    "remove_default_margin",
    "readOnly",
    "disabled",
    "accept",
    "min",
  ],
  data() {
    return { inputfield: this.value };
  },
  methods: {
    selectFile() {
      this.$refs["select-file-input"].click();
    },
    fileChange(e) {
      this.$emit("change-value", e.target.files[0], this.field);
    },
  },
  watch: {
    value: function (val) {
      if (this.type !== "file") this.inputfield = val;
    },
    inputfield() {
      this.$emit("change-value", this.inputfield, this.field);
    },
  },
  computed: {
    getRootStyle() {
      return `margin-bottom: ${this.remove_default_margin ? "0px" : "20px"};`;
    },
  },
};
</script>

<style scoped>
.labelText {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}
.helper_text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.invalidInput {
  border-color: red !important;
  background-color: white !important;
}
.form-control {
  padding: 10px;
  font-size: 14px;
  font-family: Roboto_Regular;
  width: -webkit-fill-available;
  border-radius: 10px;
  border: 1px solid #edece9;
}
.form-control:focus-visible {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
.form-control:hover {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
.form-control:focus {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
.form-control[readonly] {
  background-color: #f5f3ef;
  border-color: #ebe9e5;
}
input[type="color"].form-control {
  height: 43px !important;
  border-radius: 0px !important;
}
.select-file-input {
  background-color: transparent !important;
  border-color: #edece9 !important;
}
.select-file-input.invalidInput {
  border-color: red !important;
  background-color: white !important;
}
</style>
