<template>
  <b-modal
    id="generate-final-modal"
    @show="onModalShow"
    size="lg"
    centered
    hide-header
    hide-footer
  >
  <div v-if="loading" class="loader-overlay">
      <div
        id="loading-container"
        class="p-5 d-flex justify-content-center align-items-center"
      >
        <b-spinner></b-spinner>
      </div>
    </div>

    <div class="modal-title">Score Application</div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <div>
      <base-select
        label="Select the Financial Data"
        :options="financialOptions"
        field="selectedFinancial"
        @change-value="setValue"
      ></base-select>

      <base-select
        label="And the Transactions analysis"
        :options="analysisOptions"
        field="selectedAnalysis"
        @change-value="setValue"
      ></base-select>

      <base-input
        label="And give it a name"
        placeholder="ScoreName - Date"
        type="text"
        field="label"
        :value="label"
        @change-value="setValue"
      ></base-input>
    </div>
    <b-row>
      <b-col
        offset-lg="8"
        lg="4"
        offset-sm="6"
        sm="6"
        class="d-flex align-items-center"
      >
        <base-button
          style_variant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
          @onClick="submit"
          :disabled="isSubmitDisabled"
        >
          SUBMIT
        </base-button>
        <base-button
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
          @onClick="closeModal"
        >
          CANCEL
        </base-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ApplicationsService from "@/services/applications.service";
import { ErrorMessages } from "@/lib/errorMessages";
import moment from "moment";
export default {
  name: "generate-final",
  data() {
    return {
      error: null,
      loading: false,
      analysisOptions: [],
      financialOptions: [],
      selectedFinancial: null,
      selectedAnalysis: null,
      label: null,
      companyName: null,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      let id = this.$route.params.id;

      ApplicationsService.get(`${id}/labels-financial`)
        .then(({ data }) => {
          this.financialOptions = data?.data;
          this.companyName = data.name;
        })
        .catch((e) => {
          this.error = ErrorMessages.errorFinancialData;
        });

      ApplicationsService.get(`${id}/labels-submit`)
        .then(({ data }) => {
          this.analysisOptions = data?.data;
        })
        .catch((e) => {
          this.error = ErrorMessages.errorAnalysisData;
        });
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("generate-final-modal");
    },
    setValue(val, field) {
      this[field] = val;
    },
    submit() {
      const payload = {
        analysis_id: this.selectedAnalysis,
        financial_id: this.selectedFinancial,
        label: this.label,
      };
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        this.loading = true;
        ApplicationsService.post(payload, `${id}/scores`)
          .then((res) => {
            this.loading = false;
            this.$bvModal.hide("generate-final-modal");
            this.$emit("submission-successful");
          })
          .catch((err) => {
            this.error = ErrorMessages.cantAddScore;
          }).finally(()=>{
            this.loading = false;

          })
      }
    },
    onModalShow() {
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        const today = moment();
        const formattedDate = today.format("YYYYMMDD");
        this.label = `${this.companyName}-scores-${formattedDate}`;
      }
    },
  },
  computed: {
    isSubmitDisabled() {
      return (
        this.selectedFinancial === null ||
        this.selectedAnalysis === null ||
        this.label === null
      );
    },
  },
};
</script>

<style scoped>
.modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
.cursor-grabbing {
  cursor: grabbing !important;
}

.draggable-list > div {
  cursor: pointer;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3); 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000; 
}
</style>
