<template>
    <b-modal id="ShowErrorModal" size="lg" centered hide-header hide-footer>
        <div class="modal-title">Errors</div>
        <div>{{ data }}</div>
    </b-modal>
</template>
  
<script>

export default {
    name: "ShowErrorModal",
    props: ["data"],
    data() {
        return {
        }
    },
};
</script>
  
<style scoped>
#ShowErrorModal .modal-title {
    font-size: 24px;
    font-family: Roboto_Medium;
    color: #5e595c;
    border-bottom: 2px solid #dfddd9;
    margin-bottom: 1rem;
}

</style>
  