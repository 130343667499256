<template>
  <div id="FinancialData">
    <div v-if="!loading">
      <b-alert :show="error ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </div>

    <div v-if="querying">
      <common-loading />
    </div>
    <div class="content-table">
      <div class="table-container">
        <b-row class="table-title">
          <b-col class="table-title-field">
            <div class="table-title-text">Name</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Date</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Actions</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
        </b-row>
        <b-alert :show="error ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <div v-if="querying">
          <common-loading />
        </div>
        <b-row class="table-data" v-for="(each, index) in data" :key="index"
          :class="{ 'fade-out': focusRowId == each.id }" :style="{
            backgroundColor: focusRowId == each.id ? '#53e7fc' : 'white',
          }">
          <b-col>
            <div class="com-country">
              {{ each.label }}
            </div>
          </b-col>
          <b-col class="com-info">
            <div class="com-name">
              {{ formattedDate(each.date) }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country position-rel">
              <div class="action-container" @click.stop="openMenu(index)">
                <img :src="openMenuFlag === index
                    ? '/images/eye-icon.png'
                    : '/images/dot-icon.png'
                  " :alt="openMenuFlag === index ? 'eye-icon' : 'dot-icon'" />
              </div>
              <div class="action-menu" v-if="openMenuFlag === index">
                <div class="action-menu-item" @click.stop="downloadS3(each.id, false)">
                  Download Financial file
                </div>
                <div class="action-menu-item" @click.stop="downloadS3(each.id, true)">
                  Download config file
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div>
          <base-button style_variant="primary" cus_style="font-size:15px" @onClick="handleAddFinancial">
            Add Financial
          </base-button>
        </div>
      </div>
    </div>
    <ShowErrorModal :data="errorData" />
    <FinancialModal @financial-submission-successful="handleSubmissionSuccessful" />
  </div>
</template>

<script>
//TODO: should provide a way to download the financial data
import ApplicationsService from "../../services/applications.service";
import { ErrorMessages } from "../../lib/errorMessages";
import ShowErrorModal from "../../components/common/ShowErrorModal.vue";
import FinancialModal from "../../components/common/FinancialModal.vue";
import moment from "moment";

export default {
  name: "FinancialData",
  props: {
    application_id: String,
    focusedId: String,
  },
  components: { ShowErrorModal, FinancialModal },
  data() {
    return {
      error: null,
      querying: false,
      data: [],
      errorData: "",
      loading: false,
      label: "",
      openMenuFlag: false,
      focusRowId: null,
    };
  },
  methods: {
    fadeOutRows() {
      this.focusRowId = null;
    },
    downloadS3(id, isConfig) {
      const payload = { financialId: id, isConfig: isConfig };
      const applicationId = this.$route.params.id;

      ApplicationsService.financialUrl(payload, applicationId)
        .then(({ data }) => {
          if (data) {
            window.open(data, "_blank");
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoading;
        });
    },
    openMenu(index) {
      this.openMenuFlag = this.openMenuFlag === index ? null : index;
    },
    handleSubmissionSuccessful() {
      this.query();
    },
    handleAddFinancial() {
      this.$bvModal.show("financial-modal");
      this.query();
    },
    navigateToApplication(application_id) {
      if (application_id === this.$route.params.id) return;
      this.$router.push(`/applications/${application_id}`);
    },
    query(params = {}) {
      //TODO: application ID shouldn't be passed as param
      params["application_id"] = this.application_id;
      this.error = null;
      this.querying = true;
      this.data = [];
      this.loading = true;
      ApplicationsService.getApplicationFinancial(this.application_id, params)
        .then(({ data }) => {
          if (!data || data.data.length < 1) {
            this.error = ErrorMessages.noData;
          } else {
            this.data = data?.data.map((item) => {
              return {
                application_id: item.application_id,
                label: item.label,
                date: item.created_at,
                id: item.id,
                file_location: item.file_location,
              };
            });
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoading;
        })
        .finally(() => {
          this.querying = false;
          this.loading = false;
        });
    },
    //TODO: Should we move this to be global helper or component
    formattedDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    openShowErrorModal(data) {
      if (!data) return;
      this.errorData = data;
      this.$bvModal.show("ShowErrorModal");
    },
  },
  watch: {
    $route(val) {
      if (val) this.query();
    },
  },
  mounted() {
    this.querying = true;
    setTimeout(() => {
      this.fadeOutRows();
    }, 10000);
    this.query();
    this.focusRowId = this.focusedId;
    this.querying = false;
  },
};
</script>

<style scoped>
.fade-out {
  transition: background-color 3s ease;
}

.position-rel {
  position: relative;
}

.action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  z-index: 99999;
}

.action-menu-item {
  cursor: pointer;
  color: blue;
  font-size: 13px;
  font-weight: 500;
}

#FinancialData {
  padding: 1rem 1rem;
}

#FinancialData .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}

#FinancialData .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

#FinancialData .table-container {
  padding: 0.5rem;
}

#FinancialData .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

#FinancialData .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}

#FinancialData .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}

#FinancialData .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}

#FinancialData .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

#FinancialData .com-info {
  display: flex;
  align-items: center;
}

#FinancialData .com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
}

#FinancialData .com-country {
  font-size: 13px;
  color: #8f8b8d;
}

#FinancialData .link-s3 {
  font-size: 13px;
  color: blue;
  font-weight: 600;
}

#FinancialData .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
</style>
