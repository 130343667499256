<template>
  <div
    id="loading-container"
    class="p-5 d-flex justify-content-center align-items-center"
  >
    <b-spinner></b-spinner>
    <span class="loading-label">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "common-loading",
};
</script>

<style scoped>
#loading-container .loading-label {
  margin-left: 1rem;
  font-family: "Roboto_Medium";
  color: #260b19;
  font-size: 18px;
}
</style>
