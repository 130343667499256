<template>
    
  <b-modal
    id="email-modal"
    size="lg"
    centered
    hide-header
    hide-footer
    @hidden="resetForm"
  >
  <div v-if="loading" class="loader-overlay">
      <div
        id="loading-container"
        class="p-5 d-flex justify-content-center align-items-center"
      >
        <b-spinner></b-spinner>
      </div>
    </div>
    <div class="modal-title">Email</div>
    <div class="settings-wrapper">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <validation-observer ref="observerEmail">
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationProvider
              rules="required"
              name="subject"
              v-slot="{ errors }"
            >
              <base-input
                label="Subject"
                type="text"
                field="subject"
                placeholder="Subject of email"
                :value="subject"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
            <ValidationProvider
              rules="required"
              name="content"
              v-slot="{ errors }"
            >
              <div class="form-group">
                <label class="eligible-label" for="content">Content</label>
                <textarea
                  id="content"
                  class="form-control"
                  v-model="content"
                  placeholder="Enter email content"
                  :class="{ 'is-invalid': errors && errors.length }"
                ></textarea>
                <span class="helper-text" v-if="errors && errors.length">{{
                  errors[0]
                }}</span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col lg="12" sm="12" class="py-2">
            <base-button
              :style_variant="'primary'"
              cus_style="font-size:15px"
              @onClick="submit"
              :disabled="isSubmitDisabled"
            >
              SUBMIT
            </base-button>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
//TODO: Should add rejection reason 
//TODO: Should include cancel button
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import { ErrorMessages } from "@/lib/errorMessages";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ["data", "emailData","onSubmit"],
  emits: ["refresh-page"],
  data() {
    return {
      error: null,
      subject: null,
      content: null,
      email: null,
      name: null,
      action: null,
      loading: false,
    };
  },
  methods: {
    resetForm() {
      if (this.data) {
        this.email = this.data.email;
        this.name = this.data.name;
      }
      this.subject = "Growth Funding Application Status";
      this.content = this.emailData;
      this.action = null;
      this.error = null;
    },
    setValue(val, field) {
      this[field] = val;
    },
    async submit() {
      const isValid = await this.$refs.observerEmail.validate();
      if (!isValid) return;

      const payload = {
        subject: this.subject,
        content: this.content.replace(/\n/g, "<br>"),
        username: this.name,
        email: this.email,
      };

      try {
        this.onSubmit(payload)
        this.$bvModal.hide("email-modal");
      } catch {
        this.error = ErrorMessages.errorUploading;
      }
    },
  },

  computed: {
    isSubmitDisabled() {
      return !this.subject || !this.content;
    },
  },
  watch: {
    data: {
      handler: function (newData) {
        if (newData) {
          this.email = newData.email;
          this.name = newData.name;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.data) {
      this.error = ErrorMessages.noData;
    }
    this.email = this.data.email;
    this.name = this.data.name;
    this.subject = "Growth Funding Application Status";
    this.content = this.emailData;
  },
};
</script>

<style scoped>
textarea {
  min-height: 200px !important;
}

.modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 1rem;
}

.wrapper {
  padding: 80px;
}

.labelText {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}

.helper-text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.form-control:focus-visible {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:hover {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}
</style>
