<template>
  <div id="chart-container">
    <div class="main-container">
      <b-row>
        <b-col md="7">
          <div style="position: relative; margin-bottom: 0.5rem">
            <div
              :class="[
                'chart-select',
                { active: open_chart_menu ? true : false },
                'd-flex',
                'align-items-center',
                'justify-content-between',
              ]"
              @click.stop="toggle_chart_menu"
            >
              <div>
                <div class="title">
                  {{ selected_chart_rec.name }}
                  <span v-if="selected_chart_rec.symbol">
                    {{ selected_chart_rec.symbol }}
                  </span>
                </div>
                <div>
                  <span
                    class="sub-title"
                    :style="
                      'background-color:' + selected_chart_rec.color + ';'
                    "
                  >
                    {{ selected_chart_rec.label }}
                  </span>
                </div>
              </div>
              <div>
                <img
                  src="/images/polygon.png"
                  alt="polygon"
                  :style="open_chart_menu ? 'transform: rotate(180deg)' : ''"
                />
              </div>
            </div>
            <common-menu
              custom_style="left: 0px; top: 80px;width: 525px;"
              :open_menu="open_chart_menu"
              :menu_items="
                app_data.map((each) => {
                  return (
                    each.name + ' ' + (each.symbol ? `${each.symbol}` : '')
                  );
                })
              "
              :selected_item="selected_chart_rec"
              @select_item="select_chart"
            />
          </div>
          <div
            v-if="phase === 1 && false"
            class="range-selection d-flex align-items-center justify-content-between"
          >
            <div style="position: relative; flex: 1 auto">
              <div
                :class="[
                  'chart-select',
                  { active: open_range_1 ? true : false },
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                ]"
                @click.stop="toggle_range_1"
              >
                <div style="font-size: 15px">
                  <span style="color: #8f8b8d">From: </span>
                  <span style="color: #260b19">01-01-2021</span>
                </div>
                <div>
                  <img
                    src="/images/polygon.png"
                    alt="polygon"
                    :style="open_range_1 ? 'transform: rotate(180deg)' : ''"
                  />
                </div>
              </div>
              <common-menu
                custom_style="left: 0px; top: 48px;width: 120px;"
                :open_menu="open_range_1"
                :menu_items="date_range_items"
                :selected_item="selected_range_1"
                @select_item="select_range_1"
              />
            </div>
            <div style="margin: 0rem 1rem">-</div>
            <div style="position: relative; flex: 1 auto">
              <div
                :class="[
                  'chart-select',
                  { active: open_range_2 ? true : false },
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                ]"
                @click.stop="toggle_range_2"
              >
                <div style="font-size: 15px">
                  <span style="color: #8f8b8d">To: </span>
                  <span style="color: #260b19">01-01-2021</span>
                </div>
                <div>
                  <img
                    src="/images/polygon.png"
                    alt="polygon"
                    :style="open_range_2 ? 'transform: rotate(180deg)' : ''"
                  />
                </div>
              </div>
              <common-menu
                custom_style="left: 0px; top: 48px;width: 120px;"
                :open_menu="open_range_2"
                :menu_items="date_range_items"
                :selected_item="selected_range_2"
                @select_item="select_range_2"
              />
            </div>
          </div>
        </b-col>
        <b-col md="5" class="d-flex justify-content-end" >
          <div
            @click.stop="changeCharts"
            style="padding-top: 5px; padding-right: 5px; cursor: pointer"
            v-show="!isDashboard"
          >
            <img
              :src="'/images/edit.png'"
              :alt="'edit'"
              height="30px"
              width="30px"
            />
          </div>
          <div :class="['info-icon']" v-b-modal.charts-modal></div>
          <div
            :class="[
              'icon1',
              {
                active: phase === 1 ? true : false,
              },
            ]"
            @click.stop="changePhase(1)"
          ></div>
          <div
            :class="[
              'icon2',
              {
                active: phase === 2 ? true : false,
              },
            ]"
            @click.stop="changePhase(2)"
          ></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="border: 1px solid #dfddd9; margin: 1rem 0rem"></div>
          <div>
            <div v-if="phase === 1">
              <canvas id="chart" class="canvas-w-h"></canvas>

              <b-row class="mt-3">
                <b-col
                  v-for="(each, index) in selected_chart_rec.averages"
                  :key="index"
                >
                  <div class="map-tab">
                    <div class="benchmark">Last {{ each.period }} months</div>

                    <div class="percentage">
                      {{ each.value }}
                      <span v-if="selected_chart_rec['symbol']">{{
                        selected_chart_rec["symbol"]
                      }}</span>
                    </div>

                    <div
                      class="benchmark"
                      v-if="selected_chart_rec.benchmarks.length"
                    >
                      {{ getBenchmark(each.value) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-if="phase === 2">
              <div
                class="d-flex align-items-center"
                v-if="selected_chart_rec.benchmarks.length"
              >
                <div
                  class="tab"
                  v-for="(benchmark, index) in selected_chart_rec.benchmarks"
                  :key="index"
                  :class="benchmark.active ? 'active-benchmark-classs' : ''"
                >
                  {{ benchmark.description }}
                </div>
              </div>
              <div
                class="d-flex align-items-center flex-wrap"
                style="margin-top: 1rem"
              >
                <div
                  v-for="(column, i) in filtered_columns"
                  :key="i"
                  class="d-flex align-items-center flex-wrap"
                >
                  <div
                    class="block"
                    v-for="(gist, j) in selected_chart_rec.gists"
                    :key="j"
                  >
                    <div class="date">{{ gist.date }}</div>

                    <div class="per">
                      {{ gist[column.name] }}
                      <span v-if="selected_chart_rec['symbol']">
                        {{ selected_chart_rec["symbol"] }}
                      </span>
                    </div>

                    <div
                      class="state"
                      v-if="selected_chart_rec.benchmarks.length"
                    >
                      {{ getBenchmark(gist[column.name]) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-modal
      title="Help Modal"
      size="md"
      centered
      hide-footer
      hide-header
      id="charts-modal"
    >
      <chart-help-modal
        :selected_chart="this.selected_chart_rec.name"
        :theId="'charts-modal'"
      >
      </chart-help-modal>
    </b-modal>
  </div>
</template>

<script>
//TODO: tooltip contrast is too high and not good for eye
//TODO: I thing the font is not good enough 
//TODO: In SaaS quick Raio, the bar has a border color which stronger (darker) then the body, I believe make it one color is better
//TODO: Should hide 'edit' in the dashboard, (pass it as properity)
import ChartHelpModal from "./HelpModal.vue";
import Chart from "chart.js/auto";

export default {
  components: {
    ChartHelpModal,
  },
  props: ["app_data","isDashboard"],
  data() {
    return {
      phase: 1,
      open_chart_menu: false,
      selected_chart_rec: "",
      date_range_items: [
        "01-01-2021",
        "01-01-2021",
        "01-01-2021",
        "01-01-2021",
        "01-01-2021",
      ],
      open_range_1: false,
      selected_range_1: "",
      open_range_2: false,
      selected_range_2: "",
      filtered_columns: [],
      chartInstance: null,
    };
  },
  methods: {
    changePhase(val) {
      if (val === 2) this.chartInstance.destroy();
      this.phase = val;
    },
    toggle_chart_menu() {
      this.open_chart_menu = !this.open_chart_menu;
      this.open_range_1 = false;
      this.open_range_2 = false;
    },
    select_chart(menu_item) {
      const rec = this.app_data.find((each) => {
        const chart_name =
          each.name + " " + (each.symbol ? `${each.symbol}` : "");
        return chart_name === menu_item;
      });
      if (rec) this.selected_chart_rec = rec;
      this.toggle_chart_menu();
    },
    toggle_range_1() {
      this.open_range_1 = !this.open_range_1;
      this.open_chart_menu = false;
    },
    select_range_1(menu_item) {
      this.selected_range_1 = menu_item;
      this.toggle_range_1();
    },
    toggle_range_2() {
      this.open_range_2 = !this.open_range_2;
      this.open_chart_menu = false;
    },
    select_range_2(menu_item) {
      this.selected_range_1 = menu_item;
      this.select_range_2();
    },
    findActiveChartsBenchmark() {
      let label = this.getBenchmark(this.selected_chart_rec.averages[2].value);
      for (let j = 0; j < this.selected_chart_rec.benchmarks.length; j++) {
        this.selected_chart_rec.benchmarks[j].active =
          this.selected_chart_rec.benchmarks[j].label === label;
      }
    },
    getBenchmark(val) {
      for (let i = 0; i < this.selected_chart_rec.benchmarks.length; i++) {
        const element = this.selected_chart_rec.benchmarks[i];
        let label = element.label,
          condition = "";

        for (let j = 0; j < element.tests.length; j++) {
          const element1 = element.tests[j];
          condition += `${j > 0 ? " &&" : ""} ${val} ${element1.condition} ${
            element1.value
          }`;
        }

        if (condition && eval(condition)) {
          return label;
        }
      }
    },
    changeCharts() {
      this.$bvModal.show("change-charts-modal");
    },
    makeChart(chart_data) {
      const canvas = document.getElementById("chart");
      if (!canvas) {
        console.error("Canvas element not found.");
        return;
      }
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        console.error("Unable to get 2D context for canvas.");
        return;
      }

      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      let secondYAxis = {
        type: "linear",
        beginAtZero: true,
        stacked: true,
        position: "right",
        grid: {
          display: false,
        },
      };
      let secondYAxisActive;
      this.chartInstance = new Chart(ctx, {
        type: chart_data.type,
        data: {
          labels: chart_data.gists?.map((each) => each.date),
          datasets: chart_data.columns?.map((col) => {
            const isSaasQuickRatio =
              col.name === "saas_quick_ratio" ||
              col.name === "net_mrr_growth_rate";
            const isSaasQuickRatio1 =
              col.name === "saas_quick_ratio" ||
              col.name === "net_mrr_growth_rate" ||
              col.name === "new_arr" ||
              col.name === "churned_arr" ||
              col.name === "expansion_arr" ||
              col.name === "resurrect_arr" ||
              col.name === "contracted_arr";

            secondYAxisActive = isSaasQuickRatio1
              ? secondYAxis
              : { display: false };

            return {
              type: isSaasQuickRatio ? "line" : chart_data.type,
              label: col.label,
              data: chart_data.gists?.map((each) => each[col.name]),
              test: chart_data.benchmarks?.map((each) => each[col.label]),
              backgroundColor: chart_data.basicColor,
              borderColor: chart_data.basicColor,
              fill: isSaasQuickRatio ? null : chart_data.fill,
              stacked: chart_data.stacked,
              yAxisID: isSaasQuickRatio ? "y1" : "y",
            };
          }),
        },
        options: {
          barThickness: 20,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              offset: true,
              beginAtZero: false,
              categoryPercentage: 1.0,
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              type: "linear",
              beginAtZero: true,
              stacked: true,
              position: "left",
              grid: {
                display: false,
              },
            },
            y1: secondYAxisActive,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          plugins: {
            tooltip: {
              intersect: false,
              callbacks: {
                label: (e) => {
                  let benchmark = this.getBenchmark(e.parsed.y);
                  return (
                    e.parsed.y +
                    (chart_data.symbol ? chart_data.symbol : "") +
                    "(" +
                    benchmark +
                    ")"
                  );
                },
              },
              titleColor: "#FEFFEC",
            },
          },
          legend: {
            display: false,
          },
        },
      });
    },
  },
  watch: {
    selected_chart_rec(chart_data) {
      this.filtered_columns = chart_data.columns.filter((each) =>
        chart_data.name === "SaaS Quick Ratio"
          ? each.name === "saas_quick_ratio"
          : chart_data.name === "Net MRR Growth Rate"
          ? each.name === "net_mrr_growth_rate"
          : each
      );

      this.findActiveChartsBenchmark();
      if (this.phase === 1)
        setTimeout(() => {
          this.makeChart(chart_data);
        }, 500);
    },
    phase(val) {
      if (val === 1) {
        setTimeout(() => {
          this.makeChart(this.selected_chart_rec);
        }, 500);
      }
    },
  },
  mounted() {
    if (this.app_data) {
      this.selected_chart_rec = this.app_data[0];
      this.findActiveChartsBenchmark();
    }
  },
};
</script>

<style scoped>
.canvas-w-h {
  width: 100% !important;
  height: 400px !important;
}

#chart-container {
  margin-top: 20px;
}
#chart-container .main-container {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 1px 2px 29px #1717241a;
}
#chart-container .chart-select {
  border-radius: 10px;
  background-color: #efede900;
  border: 1px solid #dfddd9;
  padding: 0.5rem;
  cursor: pointer;
}
#chart-container .chart-select.active {
  background-color: #efede9;
  border-color: #43102a;
}
#chart-container .title {
  font-size: 20px;
  color: #260b19;
}
#chart-container .sub-title {
  padding: 0.2rem 0.5rem;
  font-size: 11px;
  font-family: Roboto_Bold;
  text-transform: uppercase;
  color: #260b19;
}
#chart-container .map-container {
  width: 100% !important;
  height: 400px;
  padding: 1rem 0rem;
}
#chart-container .tab {
  font-size: 13px;
  font-family: Roboto_Medium;
  border-radius: 5px;
  padding: 0.5rem;
  margin-right: 0.5rem;
  color: #5e595c;
  background-color: #f5f3ef;
}

#chart-container .map-tab {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px #17172429;
  text-align: center;
  padding: 20px;
}
#chart-container .map-tab .percentage {
  font-size: 22px;
  font-family: Roboto_Bold;
  color: #93c7d6;
}

#chart-container .map-tab .benchmark {
  font-size: 15px;
  font-family: Roboto_Regular;
  color: #5e595c;
}
#chart-container .block {
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  text-align: center;
}
#chart-container .block .date {
  font-size: 15px;
  color: #5e595c;
}
#chart-container .block .per {
  font-size: 22px;
  font-family: Roboto_Bold;
  color: #93c7d6;
}
#chart-container .block .state {
  font-size: 15px;
  color: #5e595c;
}
#chart-container .icon1 {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: #fcfbfa;
  cursor: pointer;
  margin-right: 0.5rem;
  background-image: url(../../assets/chart-icon-1-inactive.png);
  background-repeat: no-repeat;
  background-position: center;
}
#chart-container .icon1.active,
#chart-container .icon1:hover {
  background-color: #dfddd9;
  background-image: url(../../assets/chart-icon-1.png);
}
#chart-container .icon2 {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: #fcfbfa;
  cursor: pointer;
  margin-right: 0.5rem;
  background-image: url(../../assets/chart-icon-2-inactive.png);
  background-repeat: no-repeat;
  background-position: center;
}
#chart-container .icon2.active,
#chart-container .icon2:hover {
  background-color: #dfddd9;
  background-image: url(../../assets/chart-icon-2.png);
}

#chart-container .info-icon {
  width: 40px;
  height: 40px;
  /* UI Properties */
  background: var(--unnamed-color-e6f0fc) 0% 0% no-repeat padding-box;
  background: #e6f0fc 0% 0% no-repeat padding-box;
  background-image: url("../../assets/info_icon.png");
  border-radius: 8px;
  opacity: 1;
  margin-right: 0.5rem;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.active-benchmark-classs {
  background-color: rgb(232, 223, 245) !important;
}
</style>
