<template>
  <div id="detail-container">
    <b-row>
      <b-col lg="6" sm="12">
        <base-input
          label="Company Name"
          type="text"
          :value="company_data.name"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Work Email Address"
          type="text"
          :value="company_data.email"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Mobile Phone Number"
          type="text"
          :value="company_data.phone"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Company Website URL"
          type="text"
          :value="company_data.website"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Place of Operations"
          type="text"
          :value="company_data.country"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Date of Establishment"
          type="text"
          :value="new Date(company_data.established_at).toDateString()"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Company Business Type"
          type="text"
          :value="company_data.types.join(',')"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Subscription Management Tool"
          type="text"
          :value="company_data.platforms.join(',')"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["company_data"],
  computed: {
    getInputStyle() {
      return `text-align:right;color:#8F8B8D;font-family:Roboto_Italic;font-size:13px;`;
    },
  },
};
</script>

<style scoped>
#detail-container {
  padding: 1rem;
}
</style>
