<template>
    <b-modal id="confirmation-modal" size="lg" centered hide-header hide-footer>
        <div class="modal-title">Confirmation Dialog </div>
        <div class="settings-wrapper">
            <div>
                <h5>{{modalText}}</h5>
                <p>This action cannot be undone.</p>
            </div>
            <b-row>
                <b-col lg="6" sm="6">
                    <base-button style_variant="primary" cus_style="font-size:15px" @onClick="closeModal">
                        Cancel
                    </base-button>
                </b-col>
                <b-col lg="6" sm="6">
                    <base-button style_variant="primary" cus_style="font-size:15px" @onClick="handleSubmit">
                        Confirm
                    </base-button>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
  
<script>

export default {
    props: ["onSubmit","text"],
    emits: ["refresh-page"],
    data() {
        return {
            modalText: "",
        }
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("confirmation-modal")
        },
        handleSubmit() {
            this.onSubmit()
            this.$emit("refresh-page");
            this.closeModal()
        },
    },
    watch: {
        text(newText) {
            this.modalText = newText;
        },
    },
    mounted() {
        this.modalText = this.text;
    }

};
</script>
<style scoped>
.modal-title {
    font-size: 24px;
    font-family: Roboto_Medium;
    color: #5e595c;
    border-bottom: 2px solid #dfddd9;
    margin-bottom: 1rem;
}

.settings-wrapper {
    padding: 20px;
    text-align: center;
}

h5 {
    font-size: 18px;
    margin-bottom: 15px;
}

p {
    font-size: 14px;
    color: #777;
    margin-bottom: 30px;
}
</style>
  