<template>
  <div id="companies-container">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="title">Companies</div>
      <div>
        <base-button
          style_variant="primary"
          cus_style="font-size:15px"
          @onClick="openModal()"
        >
          CREATE COMPANY
        </base-button>
      </div>
    </div>
    <company-list ref="company_list" />
    <new-company @refresh-page="query" />
  </div>
</template>

<script>
import CompanyList from "./CompanyList.vue";
import NewCompany from "./NewCompany.vue";

export default {
  name: "Companies-Page",
  components: { CompanyList, NewCompany },
  methods: {
    openModal() {
      this.$bvModal.show("new-company-modal");
    },
    query() {
      this.$refs.company_list.query();
    },
  },
};
</script>

<style scoped>
#companies-container {
  padding: 70px 60px;
}
#companies-container .title {
  font-size: 18px;
  font-family: Roboto_Medium;
  color: #8f8b8d;
  margin-bottom: 1rem;
}
#companies-container .tab {
  font-size: 15px;
  color: #8f8b8d;
  font-family: Roboto_Medium;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}
#companies-container .active_tab {
  color: #741b47;
  border-bottom: 3px solid #741b47;
}
</style>
