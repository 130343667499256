<template>
  <div id="First-Application-container">
    <b-row v-if="company_report" style="padding: 0rem 0.5rem">
      <b-col style="padding: 0rem 0.25rem">
        <div class="block" style="background-color: #bab8b9; color: #260b19">
          <div class="label">TOTAL</div>
          <div class="value">{{ company_report.total }}</div>
        </div>
      </b-col>
      <b-col style="padding: 0rem 0.25rem">
        <div class="block" style="background-color: #e8dff5; color: #411884">
          <div class="label">ELIGIBLE</div>
          <div class="d-flex align-items-center">
            <img
              src="/images/qualified-icon.png"
              alt="qualified-icon"
              style="margin-right: 0.5rem"
            />
            <div class="value">{{ company_report.accepted }}</div>
          </div>
        </div>
      </b-col>
      <b-col style="padding: 0rem 0.25rem">
        <div class="block" style="background-color: #fae3d4; color: #cc4e00">
          <div class="label">NOT ELIGIBLE</div>
          <div class="d-flex align-items-center">
            <img
              src="/images/not-qualified-icon.png"
              alt="not-qualified-icon"
              style="margin-right: 0.5rem"
            />
            <div class="value">{{ company_report.declined }}</div>
          </div>
        </div>
      </b-col>
      <b-col style="padding: 0rem 0.25rem">
        <div class="block" style="background-color: #dfddd9; color: #5e595c">
          <div class="label">PENDING</div>
          <div class="d-flex align-items-center">
            <img
              src="/images/execution-icon.png"
              alt="execution-icon"
              style="margin-right: 0.5rem"
            />
            <div class="value">{{ company_report.pending }}</div>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="top-container">
      <div class="filter-container">
        <base-input
          placeholder="Search"
          type="text"
          field="term"
          :value="term"
          @change-value="setValue"
          :remove_default_margin="true"
          :cus_style="getSearchBoxStyle"
        ></base-input>

        <div style="position: relative">
          <base-button
            style_variant="primary"
            :cus_style="getFilterButtonStyle"
            @onClick="toggle_filter_menu"
          >
            <img
              src="/images/sort.png"
              alt="sort"
              style="margin-right: 0.5rem; height: 18px; width: 18px"
            />
            <span v-if="this.$route.query.filter">
              {{ this.$route.query.filter.split(",").length }}
              {{
                this.$route.query.filter.split(",").length > 1
                  ? "Filters"
                  : "Filter"
              }}
            </span>
            <span v-else> Filters </span>
          </base-button>
          <common-menu
            custom_style="left: 0px; top: 50px;"
            :open_menu="open_filter_menu"
            :menu_items="filter_menu_items.map((each) => each.label)"
            :selected_item="selected_filter_item"
            @select_item="select_filter_item"
          />
        </div>
      </div>
      <div class="pagination-container" v-if="total_count">
        <base-pagination
          :active_page="active_page"
          :total_page="total_page"
          :total_count="total_count"
          :page_size="page_size"
          @set-page="setPage"
        />
      </div>
    </div>

    <div class="content-table">
      <div class="table-container">
        <b-row class="table-title">
          <b-col md="3" class="table-title-field">
            <div class="table-title-text">COMPANY NAME</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
          <b-col md="3" class="table-title-field">
            <div class="table-title-text">COUNTRY</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
          <b-col md="4" class="table-title-field">
            <div class="table-title-text">STATUS</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
        </b-row>
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <div v-if="querying">
          <common-loading />
        </div>
        <b-row
         
          class="table-data"
          v-for="(each, index) in data"
          :key="index"
          @click.stop="openDetail(each.id)"
        >
          <b-col md="3" class="com-info">
            <img
              v-if="each.image"
              class="com-avatar"
              :src="each.image"
              alt="com-logo"
            />
            <div
              v-else
              class="com-avatar d-flex justify-content-center align-items-center"
            >
              {{ each.name.charAt(0).toLocaleUpperCase() }}
            </div>
            <div class="com-name">{{ each.name }}</div>
          </b-col>
          <b-col md="3">
            <div class="com-country">{{ each.country }}</div>
          </b-col>
          <b-col md="6" class="state-container" style="position: relative">
            <div
              v-if="each.state"
              class="company-state flex-container"
              :style="`background-color: ${
                each.state === 'PENDING'
                  ? '#dfddd9'
                  : each.state === 'ACCEPTED'
                  ? '#e8dff5'
                  : each.state === 'DECLINED'
                  ? '#fae3d4'
                  : ''
              };border-color: ${
                each.state === 'PENDING'
                  ? '#5e595c'
                  : each.state === 'ACCEPTED'
                  ? '#411884'
                  : each.state === 'DECLINED'
                  ? '#cc4e00'
                  : ''
              };color: ${
                each.state === 'PENDING'
                  ? '#5e595c'
                  : each.state === 'ACCEPTED'
                  ? '#411884'
                  : each.state === 'DECLINED'
                  ? '#cc4e00'
                  : ''
              };`"
            >
              <img
                v-if="each.state === 'PENDING'"
                src="/images/execution-icon.png"
                alt="execution-icon"
                style="margin-right: 0.5rem; height: 18px; width: 18px"
              />
              <img
                v-if="each.state === 'ACCEPTED'"
                src="/images/qualified-icon.png"
                alt="qualified-icon"
                style="margin-right: 0.5rem; height: 18px; width: 18px"
              />
              <img
                v-if="each.state === 'DECLINED'"
                src="/images/not-qualified-icon.png"
                alt="not-qualified-icon"
                style="margin-right: 0.5rem; height: 18px; width: 18px"
              />
              <div style="margin-top: 3px">
                {{
                  each.state === "PENDING"
                    ? "PENDING"
                    : each.state === "ACCEPTED"
                    ? "ELIGIBLE"
                    : each.state === "DECLINED"
                    ? "NOT ELIGIBLE"
                    : each.state
                }}
              </div>
            </div>
            <div
              v-if="each.state && each.state === 'PENDING'"
              class="action-container"
              @click.stop="openMenu(index)"
            >
              <img
                :src="
                  openMenuFlag === index
                    ? '/images/eye-icon.png'
                    : '/images/dot-icon.png'
                "
                :alt="openMenuFlag === index ? 'eye-icon' : 'dot-icon'"
              />
            </div>
            <div class="action-menu" v-if="openMenuFlag === index">
              <div
                class="action-menu-item"
                style="margin-bottom: 0.5rem"
                @click.stop="acceptCompany(each.id)"
              >
                Change to Eligible
              </div>
              <div
                class="action-menu-item"
                @click.stop="declineCompany(each.id)"
              >
                Change to Not Eligible
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="bottom-container">
      <div class="pagination-container" v-if="total_count">
        <base-pagination
          :active_page="active_page"
          :total_page="total_page"
          :total_count="total_count"
          :page_size="page_size"
          @set-page="setPage"
        />
      </div>
    </div>

    <decline-reason-modal :company_id="company_id" @refresh-page="query" />
    <accept-company-modal :company_id="company_id" @refresh-page="query" />
  </div>
</template>

<script>
import CompanyService from '../../services/company.service';
import EnumeratesService from '../../services/enumerates.service';
import ReportsService from '../../services/reports.service';
import AcceptCompanyModal from './AcceptCompanyModal.vue';
import DeclineReasonModal from './DeclineReasonModal.vue';

export default {
  name: "First-Application-Page",
  components: { DeclineReasonModal, AcceptCompanyModal },
  data() {
    return {
      error: null,
      querying: false,
      openMenuFlag: null,
      term: "",
      page_size: 5,
      active_page: 1,
      total_page: 0,
      total_count: 0,
      data: [],
      countries: [],
      company_id: null,
      filter_menu_items: [
        {
          label: "ALL",
          state: "",
        },
        {
          label: "PENDING",
          state: "PENDING",
        },
        {
          label: "ELIGIBLE",
          state: "ACCEPTED",
        },
        {
          label: "NOT ELIGIBLE",
          state: "DECLINED",
        },
      ],
      open_filter_menu: false,
      selected_filter_item: null,
      company_report: null,
    };
  },
  methods: {
    select_filter_item(item) {
      let filters =
          this.$route.query &&
          this.$route.query.filter &&
          this.$route.query.filter.length
            ? this.$route.query.filter.split(",")
            : [],
        queryString = "";

      if (item && item !== "ALL") {
        const rec = this.filter_menu_items.find((each) => each.label === item);

        if (rec) {
          const index = filters.findIndex((each) => each === rec.state);

          if (index >= 0) filters.splice(index, 1);
          else filters.push(rec.state);
        }
      } else {
        filters = [];
      }

      const params = {
        filter: filters.join(","),
      };

      queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");

      this.$router.push(`/companies?${queryString}`);

      this.selected_filter_item = item;
      this.toggle_filter_menu();
    },
    openMenu(index) {
      if (this.openMenuFlag === index) this.openMenuFlag = null;
      else this.openMenuFlag = index;
    },
    setValue(val, field) {
      this[field] = val;
    },
    setPage(page) {      
      this.active_page = page;
    },
    openDetail(id) {
      this.$router.push(`/companies/${id}`);
    },
    toggle_filter_menu() {
      this.open_filter_menu = !this.open_filter_menu;
    },
    query(params = {}) {
      params["page"] = this.active_page || 1;
      params["limit"] = this.page_size;

      if (this.term) params.search = this.term;

      if (this.$route.query.filter) {
        params["state"] = this.$route.query.filter;
      }
      this.error = null;
      this.querying = true;
      this.data = [];
      this.total_count = 0;

      CompanyService.query(params)
        .then(({ count, objects }) => {
          if (!count) {
            this.error = "No companies found.";
          } else {
            this.total_count = count;
            this.data = objects.map((item) => {
              if (item.country && this.countries.length)
                item.country = this.countries.find(
                  (each) => each.id === item.country
                ).name;
              return item;
            });
          }
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading companies.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
    report() {
      this.querying = true;

      ReportsService.companies()
        .then((data) => {
          this.company_report = data;
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading companies report.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
    acceptCompany(id) {
      this.openMenu();
      this.company_id = id;
      this.$bvModal.show("accept-company-modal");
    },
    declineCompany(id) {
      this.openMenu();
      this.company_id = id;
      this.$bvModal.show("decline-reason-modal");
    },
  },
  computed: {
    getSearchBoxStyle() {
      return `margin-right: 1rem;
      border: 1px solid #dfddd9;
      border-radius: 10px;
      background-color: transparent;
      `;
    },
    getFilterButtonStyle() {
      return `font-size: 15px;
      border-radius: 10px;
      border: 1px solid #dfddd9;
      background-color: transparent;
      color:#8F8B8D;`;
    },
  },
  watch: {
    total_count(val) {
      this.total_page = Math.ceil(val / this.page_size);
    },
    term() {
      this.active_page = 1;
      this.query();
    },
    active_page() {
      this.query();
    },
    $route(val) {
      if (val) this.query();
    },
  },
  mounted() {
    this.querying = true;
    EnumeratesService.countries()
      .then((data) => {
        if (data && data.length)
          this.countries = data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        this.querying = false;
        this.query();
        this.report();
      });
  },
};
</script>

<style scoped>
#First-Application-container {
  padding: 1rem 0rem;
}
#First-Application-container .title {
  font-size: 18px;
  font-family: Roboto_Medium;
  color: #8f8b8d;
  margin-bottom: 1rem;
}
#First-Application-container .block {
  padding: 10px 20px;
  margin-bottom: 1rem;
  border-radius: 5px;
}
#First-Application-container .label {
  font-size: 14px;
  font-family: Roboto_Medium;
}
#First-Application-container .value {
  font-size: 16px;
  font-family: Roboto_Bold;
}
#First-Application-container .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}
#First-Application-container .filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#First-Application-container .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}
#First-Application-container .table-container {
  padding: 0.5rem;
}
#First-Application-container .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}
#First-Application-container .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}
#First-Application-container .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}
#First-Application-container .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}
#First-Application-container .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}
#First-Application-container .com-avatar {
  margin-right: 0.5rem;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ebdfe5;
  color: #260b19;
  font-size: 14px;
  font-family: Roboto_Medium;
}
#First-Application-container .com-avatar img {
  border-radius: 50%;
}
#First-Application-container .com-info {
  display: flex;
  align-items: center;
}
#First-Application-container .com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
}
#First-Application-container .com-country {
  font-size: 13px;
  color: #8f8b8d;
}
#First-Application-container .state-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#First-Application-container .company-state {
  padding: 0.2rem 0.5rem;
  border: 1px solid #8f8b8d;
  color: #8f8b8d;
  font-size: 11px;
  font-family: "Roboto_Bold";
  border-radius: 5px;
  display: flex;
  align-items: center;
}
#First-Application-container .action-container {
  height: 30px;
  width: 30px;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
#First-Application-container .action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  right: 0;
  bottom: -65px;
  z-index: 99999;
}
#First-Application-container .action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}
#First-Application-container .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
</style>
