import Vue from "vue";

import ResourceService from "./resource.service";

class ApplicationsService extends ResourceService {
  static get resource() {
    return "admin/applications";
  }

  static changeStatus(application_id, status, payload) {
    const url = `${this.resource}/${application_id}/${status}`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .post(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateApplication(application_id, payload) {
    const url = `${this.resource}/${application_id}/update`;
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static uploadTransactionalData(application_id, payload) {
    return ApplicationsService.post(payload, `${application_id}/application-submit`);
  }

  static getAllAnalysis(params) {
    const queryParams = new URLSearchParams(params).toString();
    return ApplicationsService.get(`submits-status?${queryParams}`);
  }
  static getAnalysisSummary() {
    return ApplicationsService.get("submits-stat");
  }

  static applicationDownloadOutput(payload) {
    const url = `${this.resource}/output`;
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getApplicationAnalysis(params) {
    const queryParams = new URLSearchParams(params).toString();
    return ApplicationsService.get(`company-applications?${queryParams}`);
  }

  static shareApplication(application_id, payload) {
    const url = `${this.resource}/${application_id}/share`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .post(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getApplicationDetails(application_id) {
    return ApplicationsService.get(`${application_id}`);
  }

  static getScores(application_id) {
    return ApplicationsService.get(`${application_id}/scores`);
  }

  static getApplicationFinancial(application_id, params) {
    const queryParams = new URLSearchParams(params).toString();
    return ApplicationsService.get(`${application_id}/financial?${queryParams}`);
  }

  static getExcelSheets(payload, application_id) {
    return this.post(payload, `${application_id}/financial/sheets`);
  }

  static getSheetRows(payload, application_id) {
    return ApplicationsService.post(payload, `${application_id}/financial/mapping`);
  }

  //TODO: link should be applications/application_id/financial_id/mapping
  static saveMappingData(payload, application_id) {
    return ApplicationsService.post(payload, `${application_id}/financial/save`);
  }

  static financialUrl(payload, application_id) {
    return this.post(payload, `${application_id}/financial-url`);
  }
  static analysisUrl(payload, application_id) {
    return this.post(payload, `${application_id}/analysis-url`);
  }

  static reanalyse(appId, analysisId) {
    return this.get(`${appId}/analysis/${analysisId}/reanalyse`);
  }
  static scoresUrl(payload, application_id) {
    return this.post(payload, `${application_id}/scores-url`);
  }

  static sendUpdate(payload, application_id) {
    return this.post(payload, `${application_id}/system-update`);
  }

  static changeBucketRating(payload, application_id) {
    return this.post(payload, `${application_id}/bucket-rating`);
  }
}

export default ApplicationsService;
