<template>
  <b-modal
    id="edit-modal"
    size="lg"
    centered
    hide-header
    hide-footer
    @hidden="resetForm"
  >
    <div class="modal-title">Edit User Info</div>
    <div class="settings-wrapper">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <validation-observer ref="observerEdit">
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationProvider
              rules="min:8"
              name="new password"
              v-slot="{ errors }"
            >
              <base-input
                label="New password"
                type="text"
                field="password"
                placeholder="leave blank to keep current password"
                :value="password"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
            <ValidationProvider
              rules="required"
              name="first name"
              v-slot="{ errors }"
            >
              <base-input
                label="First name"
                type="text"
                field="firstName"
                placeholder="First name"
                :value="firstName"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
            <ValidationProvider
              rules="required"
              name="last name"
              v-slot="{ errors }"
            >
              <base-input
                label="Last name"
                type="text"
                field="lastName"
                placeholder="Last name"
                :value="lastName"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12" class="py-2">
            <base-button
              :style_variant="isSubmitDisabled ? 'secondary' : 'primary'"
              cus_style="font-size:15px"
              @onClick="submit"
              :disabled="isSubmitDisabled"
            >
              SUBMIT
            </base-button>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import SettingsService from "@/services/settingsDashboard.service";
import { ErrorMessages } from "@/lib/errorMessages";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ["data"],
  emits: ["refresh-page"],
  data() {
    return {
      error: null,
      password: null,
      firstName: null,
      lastName: null,
    };
  },
  methods: {
    resetForm() {
      if (this.data) {
        this.firstName = this.data.first_name;
        this.lastName = this.data.last_name;
      }
      this.password = null;
    },
    setValue(val, field) {
      this[field] = val;
    },
    async submit() {
      const isValid = await this.$refs.observerEdit.validate();
      if (!isValid) return;
      if (!this.data) {
        this.error = ErrorMessages.noData;
        return;
      }
      const payload = {
        id: this.data.id,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
      };
      SettingsService.updateUser(payload)
        .then((res) => {
          this.$emit("refresh-page");
          this.$bvModal.hide("edit-modal");
        })
        .catch((e) => {
          this.error = ErrorMessages.errorUploading;
        });
    },
  },

  computed: {
    isSubmitDisabled() {
      return !this.firstName || !this.lastName;
    },
  },
  watch: {
    data: {
      handler: function (newData) {
        if (newData) {
          this.firstName = newData.first_name;
          this.lastName = newData.last_name;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.data) {
      this.firstName = this.data.first_name;
      this.lastName = this.data.last_name;
    }
  },
};
</script>

<style scoped>
.modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 1rem;
}

wrapper {
  padding: 80px;
}

.labelText {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}

.helper-text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.form-control:focus-visible {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:hover {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
</style>
