<template>
  <div>Logout</div>
</template>

<script>
import { LOGOUT } from "@/store/auth.module";

export default {
  name: "Logout-page",
  mounted() {
    this.$store
      .dispatch(LOGOUT)
      .then(() => this.$router.push({ name: "login" }));
  },
};
</script>
