import ResourceService from "./resource.service";


export default class OnboardingService extends ResourceService {
  static get resource() {
    return "admin/onboarding";
  }

  static userDetails(id) {
    return OnboardingService.get(id);
  }

  static actions(id, action, email='') {

    return OnboardingService.post({'action': action ,'email': email}, `${id}/actions`);
  }

  static applications(params) {
    return OnboardingService.get('', params);

  }  

  static download(payload) {
    return OnboardingService.post(payload, 'download')
  }  
}