export default {
  install(Vue) {
    // define a global property
    Vue.VERSION = "v2.6.14";

    // define an instance method
    Vue.prototype.$amount = function (value) {
      return (
        "$ " +
        (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0)
      );
    };
  },
};
