var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"company-repayments-schedule-container"}},[_c('b-row',{staticClass:"table-title"},[_c('b-col',{staticClass:"table-title-field",attrs:{"md":"3"}},[_c('div',{staticClass:"table-title-text"},[_vm._v("PAYMENT ID")]),_c('img',{staticClass:"sort-icon",attrs:{"src":"/images/sort-icon.png","alt":"sort-icon"}})]),_c('b-col',{staticClass:"table-title-field",attrs:{"md":"3"}},[_c('div',{staticClass:"table-title-text"},[_vm._v("STATUS")]),_c('img',{staticClass:"sort-icon",attrs:{"src":"/images/sort-icon.png","alt":"sort-icon"}})]),_c('b-col',{staticClass:"table-title-field",attrs:{"md":"3"}},[_c('div',{staticClass:"table-title-text"},[_vm._v("DUE DATE")]),_c('img',{staticClass:"sort-icon",attrs:{"src":"/images/sort-icon.png","alt":"sort-icon"}})]),_c('b-col',{staticClass:"table-title-field",attrs:{"md":"3"}},[_c('div',{staticClass:"table-title-text"},[_vm._v("DUE AMOUNT")]),_c('img',{staticClass:"sort-icon",attrs:{"src":"/images/sort-icon.png","alt":"sort-icon"}})])],1),(_vm.loading)?_c('b-row',{staticClass:"table-data"},[_c('common-loading')],1):(_vm.error && _vm.error.length && !_vm.loading)?_c('b-row',{staticClass:"table-data"},[_c('b-alert',{attrs:{"show":_vm.error && _vm.error.length ? true : false,"variant":"danger"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.error || '&nbsp;')}})])],1):_vm._l((_vm.data),function(each,index){return _c('b-row',{key:index,staticClass:"table-data"},[_c('b-col',{staticClass:"due-date",attrs:{"md":"3"}},[_vm._v(_vm._s(each.number))]),_c('b-col',{attrs:{"md":"3"}},[_c('span',{class:[
          'status',
          {
            upcoming:
              each && each.status && each.status === 'SCHEDULED'
                ? true
                : false,
          },
          {
            overdue:
              each && each.status && each.status === 'OVERDUE' ? true : false,
          },
          {
            paid:
              each && each.status && each.status === 'PAID' ? true : false,
          },
        ]},[(each && each.status && each.status === 'PAID')?_c('span',[_vm._v(" PAID ON "+_vm._s(_vm._f("moment")(each.paid_at,"MMM DD, YYYY"))+" ")]):(each && each.status && each.status === 'SCHEDULED')?_c('span',[_vm._v(" SCHEDULED ")]):_vm._e(),(each && each.status && each.status === 'OVERDUE')?_c('span',[_vm._v(" "+_vm._s(Math.floor( Math.abs(new Date() - new Date(each.due_at)) / (1000 * 60 * 60 * 24) ))+" DAYS OVERDUE ")]):_vm._e()])]),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm._f("moment")(each.due_at,"MM-DD-YYYY"))+" ")])]),_c('b-col',{staticClass:"amount-container",staticStyle:{"position":"relative"},attrs:{"md":"3"}},[_c('div',{staticClass:"due-amount"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$amount(each.amount))}})]),(each.status !== 'PAID')?_c('div',{staticClass:"action-container",on:{"click":function($event){return _vm.openMenu(index)}}},[_c('img',{attrs:{"src":_vm.openMenuFlag === index
              ? '/images/eye-icon.png'
              : '/images/dot-icon.png',"alt":_vm.openMenuFlag === index ? 'eye-icon' : 'dot-icon'}})]):_vm._e(),(_vm.openMenuFlag === index)?_c('div',{staticClass:"action-menu"},[_c('div',{staticClass:"action-menu-item",on:{"click":function($event){return _vm.paid(each.id)}}},[_vm._v("Paid")])]):_vm._e()])],1)}),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.total_count)?_c('div',{staticClass:"pagination-container"},[_c('base-pagination',{attrs:{"active_page":_vm.active_page,"total_page":_vm.total_page,"total_count":_vm.total_count,"page_size":_vm.page_size},on:{"set-page":_vm.setPage}})],1):_vm._e()]),_c('b-modal',{attrs:{"id":"confirm-modal","size":"lg","centered":"","hide-header":"","hide-footer":""}},[_c('div',{staticClass:"modal-title"},[_vm._v(" You are about to mark this payment as paid, this action is irreversible. Are you sure you want to continue? ")]),_c('b-row',[_c('b-col',{attrs:{"lg":"2","sm":"0"}}),_c('b-col',{attrs:{"lg":"4","sm":"6"}},[_c('base-button',{attrs:{"type":"submit","style_variant":"secondary","cus_style":"font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;","loading":_vm.loading},on:{"onClick":_vm.confirmPaid}},[_vm._v(" YES ")])],1),_c('b-col',{attrs:{"lg":"4","sm":"6"}},[_c('base-button',{attrs:{"type":"submit","style_variant":"secondary","cus_style":"font-size: 15px; font-family: Roboto_Medium","disabled":_vm.loading},on:{"onClick":_vm.cancel}},[_vm._v(" CANCEL ")])],1),_c('b-col',{attrs:{"lg":"2","sm":"0"}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }