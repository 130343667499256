<template>
    <b-modal id="ApplicationFileUploadModal" size="lg" centered hide-header hide-footer>
        <div class="modal-title">Upload File</div>
        <ApplicationFileUploadForm :application_id="application_id" />
    </b-modal>
</template>
  
<script>
import ApplicationFileUploadForm from '@/pages/ApplicationFileUploadForm.vue';

export default {
    components: { ApplicationFileUploadForm },
    name: "ApplicationFileUploadModal",
    emits: ["refresh-page"],
    props: ["application_id"],
    data() {
        return {
        }
    },
};
</script>
  
<style scoped>
#ApplicationFileUploadModal .modal-title {
    font-size: 24px;
    font-family: Roboto_Medium;
    color: #5e595c;
    border-bottom: 2px solid #dfddd9;
    margin-bottom: 1rem;
}

</style>
  