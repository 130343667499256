<template>
  <div
    id="menu-container"
    class="menu-list"
    :style="custom_style"
    v-if="open_menu"
  >
    <div
      v-for="(menu_item, index) in menu_items"
      :class="[
        'menu-item',
        { active: selected_item === menu_item ? true : false },
      ]"
      :key="index"
      @click.stop="select_item(menu_item)"
    >
      {{ menu_item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "common-menu",
  emits: ["select_item"],
  props: ["custom_style", "open_menu", "menu_items", "selected_item"],
  methods: {
    select_item(menu_item) {
      this.$emit("select_item", menu_item);
    },
  },
};
</script>
