<template>
  <div id="onboardingSubmit">
    <h1>Help</h1>
    <div class="top-container">
      <div class="pagination-container" v-if="total_count">
        <base-pagination
          :active_page="active_page"
          :total_page="total_page"
          :total_count="total_count"
          :page_size="page_size"
          @set-page="setPage"
        />
      </div>
    </div>
    <div v-if="querying" class="loader-overlay">
      <div
        id="loading-container"
        class="p-5 d-flex justify-content-center align-items-center"
      >
        <b-spinner></b-spinner>
      </div>
    </div>
    <div class="content-table" v-if="data && data.length > 0">
      <div class="table-container">
        <b-row class="table-title">
          <b-col class="table-title-field">
            <div class="table-title-text">Email</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Note</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
        </b-row>
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <div v-if="querying">
          <common-loading />
        </div>
        <b-row class="table-data" v-for="(each, index) in data" :key="index">
          <b-col>
            <div class="com-country">
              {{ each.email }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country">
              {{ each.note }}
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="bottom-container">
      <div class="pagination-container" v-if="total_count">
        <base-pagination
          :active_page="active_page"
          :total_page="total_page"
          :total_count="total_count"
          :page_size="page_size"
          @set-page="setPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ErrorMessages } from "../../lib/errorMessages";

import HelpService from "@/services/help.service";

export default {
  name: "onboardingSubmit",
  components: {  },
  data() {
    return {
      error: null,
      querying: false,
      page_size: 10,
      active_page: 1,
      total_page: 0,
      total_count: 0,
      data: [],
    };
  },
  methods: {
    setPage(page) {
      this.querying = true;
      this.active_page = page;
      this.querying = false;
    },

    query(params = {}) {
      params["page"] = this.active_page || 1;
      params["limit"] = this.page_size;

      this.error = null;
      this.data = [];
      this.total_count = 0;

      HelpService.getHelpData(params)
        .then(({ data }) => {
          if (!data) {
            this.error = ErrorMessages.noData;
          } else {
            this.total_count = data.count;
            this.data = data?.data.map((item) => {
              return {
                id: item.id,
                email: item.email,
                note: item.note,
              };
            });
            this.querying = false;
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoading;
        })
        .finally(() => {
          this.querying = false;
        });
    },
  },
  watch: {
    total_count(val) {
      this.total_page = Math.ceil(val / this.page_size);
    },
    active_page() {
      this.query();
    },
    $route(val) {
      if (val) this.query();
    },
  },
  mounted() {
    this.querying = true;
    this.query();
  },
};
</script>

<style scoped>
.position-rel {
  position: relative;
}
.action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  z-index: 99999;
}
.action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}
.action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accept {
  color: rgb(102, 170, 34);
}
.declined {
  color: #741b47;
}
.delete {
  color: #741b47;
}

.refresh-btn {
  width: 113px;
  height: 36px;
  text-transform: capitalize;
  cursor: pointer;
  background-color: #e9e9ea;
  color: #394044;
  border: 1px solid #e9e9ea;
  padding: 8px 10px 8px 10px;
  gap: 8px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#onboardingSubmit {
  padding: 1rem 1rem;
}

#onboardingSubmit .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}

#onboardingSubmit .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

#onboardingSubmit .table-container {
  padding: 0.5rem;
}

#onboardingSubmit .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

#onboardingSubmit .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}

#onboardingSubmit .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}

#onboardingSubmit .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}

#onboardingSubmit .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

#onboardingSubmit .com-info {
  display: flex;
  align-items: center;
}

#onboardingSubmit .com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
}

#onboardingSubmit .com-country {
  font-size: 13px;
  color: #8f8b8d;
}

#onboardingSubmit .link-s3 {
  font-size: 13px;
  color: blue;
  font-weight: 600;
}

#onboardingSubmit .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
.nav-tabs .nav-link.active {
  color: #024058;
  background-color: transparent;
  border: none;
  font-size: 16px;
  border-bottom: 5px solid #b4e7e2;
  padding: 8px 0px 8px 0px;
}
.nav-tabs .nav-link {
  color: #394044;
  border-bottom: 5px solid #cdcfd0;
  padding: 8px 0px 8px 0px;
  margin-left: 40px;
}
.active {
  background-color: #daf3f1;
}
.tabs-wrapper.disabled .steps,
.tabs-wrapper.disabled .titles {
  color: #cdcfd0;
}
.navs {
  border-bottom-color: transparent;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}
</style>
