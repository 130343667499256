<template>
  <div class="component-container">
      <div class="header">Applications</div>
    <OnboardingSubmit />
  </div>
</template>

<script>
import OnboardingSubmit from "./onboardingSubmit.vue";
export default {
  name: "onboarding-applications",
  components: { OnboardingSubmit },
  data() {
    return {
      message: "",
      querying: false,
      data: {},
      errorMessage: null,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.header{
font-size: 20px;
font-weight: 700;
line-height: 30px;
color: #83888A;
padding: 1rem 1rem;
}
.component-container {
  padding: 20px;
}
</style>
