<template>
  <div>
    <div class="help-modal-title">{{selected_chart!=null?chartHelpData[selected_chart].title:''}}</div>
    <div style="margin-top:17px; margin-bottom:17px; background-color: #ddd; width:100%; height: 1px; display:block">&nbsp;</div>
    <div class="why-its-important">Why it's important</div>
    <div class="help-description"> {{selected_chart!=null?chartHelpData[selected_chart ].description:''}}</div>

    <div class="help-modal-close">
      <base-button
            style_variant="secondary"
            class="help-modal-close-button"
            cus_style="font-size: 15px; font-family: Roboto_Medium"
            @onClick="closeModal"
            >Close</base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../../admin/src/components/UI/BaseButton.vue';
const chartHelpData = {
  'ARR Growth Rate': {
    title:'Annual Recurring Revenue(ARR) Growth Rate is the change in annual recurring revenue over a given period.',
    description: 'Because ARR is the amount of revenue that a company expects to repeat, it enables you to measure your company progress and prediction of future growth.'
  },
  'Gross Margin': {
    title: 'Gross Margin is the revenue left over after subtracting the cost of revenue',
    description: 'It reflects the efficiency of a business in terms of making use of its direct resources. It offers insight into the cash generation potential of any given SaaS company at maturity.'
  },
  'Months to Recover CAC': {
    title: 'Customer Acquisition Cost (CAC) payback is the number of months it will take to recover the cost of acquiring a customer.',
    description: 'The shorter your payback period, the more financially efficient your acquisition methods are.'
  },
  'LTV:CAC Ratio': {
    title:'LTV:CAC ratio measures the relationship between the lifetime value of a customer and the cost of acquiring that customer.',
    description: 'It is an indication of both customer profitability and marketing effectiveness.'
  },
  'Net MRR Growth Rate':{
    title:'Net Monthly Recurring Revenue(MRR) Growth Rate measures the month over month percentage increase in net new Monthly recurring revenue.',
    description:'The net growth rate provides a solid indicator of how quickly your SaaS company is growing.'
  },
  'Gross MRR Churn Rate':{
    title:'Gross Monthly Recurring Revenue(MRR) Churn Rate is the percentage of revenue lost due to cancellation or downgrades.',
    description:'Revenue churn is the percentage of subscription dollars up for renewal that a company loses over a given period, or the ability to keep the contract value of existing customers.'
  },
  'Net Dollar Retention': {
    title:'Net Dollar retention rate measures the changes in recurring revenue caused due to fluctuations within the revenue from the existing customer base.',
    description:'It offers a holistic picture of how well you keep, engage, and grow your existing customer base.'
  },
  'Burn Rate Multiple':{
    title:'Burn Multiple measures how much a startup is burning (Total operational expenses deducting revenues) to generate each incremental dollar of ARR.',
    description:'The lower the Burn Multiple, the more efficient the growth is.'
  },
  'SaaS Quick Ratio':{
    title:'It is a quick and easy indicator of how well your top line MRR/ ARR revenue (new, expansion and resurrect) is growing relative to revenue reductions (churn and contraction).',
    description:'It acts as a red flag or a green light in terms of whether to expect net recurring revenue to increase or decrease.'
  },
  'Gross Annual Customer Churn Rate':{
    title:"The percentage of customers who discontinue their subscription over the course of a year.",
    description:"Understanding customer churn is crucial for gauging the health and stability of a business because a high churn rate leads to a higher revenue churn rate and negatively impacts the bottom line. The greater the churn rate, the more customers a business loses."
  },
  'Cohorts Charts' : {
    title: "Cohort analysis helps you understand the behaviour of a group of users which signed up in specific month apart from your user population as a whole.",
    description: "A better understanding of user trends and behaviors allows you to take steps to encourage other users to follow the same behaviour you identified in other cohorts."
  }
}


export default {
  name: "ChartHelpModal",
  props: ["selected_chart", "theId"],
  components: {BaseButton},
  data() {
    return {
      chartHelpData: chartHelpData
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.theId);
    },
  },
};
</script>

<style lang="scss" src="./HelpModal.scss"></style>
<style scoped>


.help-modal-title {
/* Layout Properties */
  margin-bottom:0;
  /* UI Properties */
  color: var(--unnamed-color-5e595c);
  text-align: left;
  font: normal normal 500 24px/32px Roboto;
  letter-spacing: 0px;
  color: #5E595C;
  opacity: 1;
}
.why-its-important {
  /* height: 24px; */
  /* UI Properties */
  color: var(--unnamed-color-260b19);
  text-align: left;
  font: normal normal bold 18px/26px Roboto;
  letter-spacing: 0px;
  color: #260B19;
}

.help-description{
  /* height: 24px; */
  /* UI Properties */
  color: var(--unnamed-color-260b19);
  text-align: left;
  font: normal normal normal 18px/26px Roboto;
  letter-spacing: 0px;
  color: #260B19;  
  margin-bottom:30px; 
}

.help-modal-close-button {
  position: absolute;
  right:25px;
  width:82px;
  height: 44px;
}

.help-modal-close{
  height: 44px;;
}
</style>
