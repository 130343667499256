<template>
  <div id="company-detail-container">
    <div class="d-inline-block">
      <div class="back-link" @click="goBack">
        <img src="/images/back-icon.png" alt="back-icon" style="margin-right: 0.5rem" />
        <div>All Applications</div>
      </div>
    </div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <div v-if="querying">
      <common-loading />
    </div>
    <div v-else-if="application_data">
      <div class="d-flex justify-content-between align-items-center mt-4 mb-4">
        <div class="d-flex align-items-center">
          <img
            v-if="application_data.company.image"
            class="com-avatar"
            :src="application_data.company.image"
            alt="com-logo"
            height="100px"
            width="100px" />
          <div v-else class="com-avatar d-flex justify-content-center align-items-center">
            {{ application_data.company.name.charAt(0).toLocaleUpperCase() }}
          </div>
          <div>
            <div class="com-name">{{ application_data.company.name }}</div>
            <div class="d-flex align-items-center">
              <div
                class="d-flex align-items-center justify-content-center"
                style="margin-right: 0.25rem; height: 20px; width: 20px">
                <img src="/images/Path 840.png" alt="Path 840" />
              </div>
              <div class="com-place">
                {{ application_data.company.country }}
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div
                class="d-flex align-items-center justify-content-center"
                style="margin-right: 0.25rem; height: 20px; width: 20px">
                <img src="/images/Path 841.png" alt="Path 841" />
              </div>
              <div class="com-email">
                {{ application_data.company.email }}
              </div>
            </div>
          </div>
        </div>

        <div style="text-align: right">
          <div class="d-inline-block">
            <!-- <div class="com-state d-flex align-items-center">
              <div
                class="d-flex align-items-center justify-content-center"
                style="margin-right: 0.25rem; height: 20px; width: 20px"
              >
                <img src="/images/gathering-icon.png" alt="gathering-icon" />
              </div>
              <div>GATHERING SINCE FEB 2, 2022</div>
            </div> -->
            <div
              class="com-state d-flex align-items-center"
              :style="`border: 1px solid ${
                application_data.state === 'SCREENING'
                  ? '#8f8b8d'
                  : application_data.state === 'GATHERING'
                  ? '#234F68'
                  : application_data.state === 'FUNDED'
                  ? '#741B47'
                  : application_data.state === 'QUALIFIED'
                  ? '#411884'
                  : application_data.state === 'DISQUALIFIED'
                  ? '#CC4E00'
                  : application_data.state === 'EXECUTION'
                  ? '#8F8B8D'
                  : application_data.state === 'COMPLETED'
                  ? '#1c9d0b'
                  : application_data.state === 'CANCELED'
                  ? '#260b19'
                  : ''
              };color: ${
                application_data.state === 'SCREENING'
                  ? '#8f8b8d'
                  : application_data.state === 'GATHERING'
                  ? '#234F68'
                  : application_data.state === 'FUNDED'
                  ? '#741B47'
                  : application_data.state === 'QUALIFIED'
                  ? '#411884'
                  : application_data.state === 'DISQUALIFIED'
                  ? '#CC4E00'
                  : application_data.state === 'EXECUTION'
                  ? '#8F8B8D'
                  : application_data.state === 'COMPLETED'
                  ? '#1c9d0b'
                  : application_data.state === 'CANCELED'
                  ? '#260b19'
                  : ''
              };`">
              <img
                v-if="application_data.state === 'SCREENING'"
                src="/images/screening-icon.png"
                alt="screening-icon"
                style="margin-right: 0.5rem" />
              <img
                v-else-if="application_data.state === 'GATHERING'"
                src="/images/gathering-icon.png"
                alt="gathering-icon"
                style="margin-right: 0.5rem" />
              <img
                v-else-if="application_data.state === 'FUNDED'"
                src="/images/funded-icon.png"
                alt="funded-icon"
                style="margin-right: 0.5rem" />
              <img
                v-else-if="application_data.state === 'QUALIFIED'"
                src="/images/qualified-icon.png"
                alt="qualified-icon"
                style="margin-right: 0.5rem" />
              <img
                v-else-if="application_data.state === 'DISQUALIFIED'"
                src="/images/not-qualified-icon.png"
                alt="not-qualified-icon"
                style="margin-right: 0.5rem" />
              <img
                v-else-if="application_data.state === 'EXECUTION'"
                src="/images/execution-icon.png"
                alt="execution-icon"
                style="margin-right: 0.5rem" />
              <img
                v-else-if="application_data.state === 'COMPLETED'"
                src="/images/completed-icon.png"
                alt="completed-icon"
                style="margin-right: 0.5rem" />
              <img
                v-else-if="application_data.state === 'CANCELED'"
                src="/images/canceled-icon.png"
                alt="canceled-icon"
                style="margin-right: 0.5rem" />
              <div style="margin-top: 2px">
                {{ application_data.state }} SINCE
                {{
                  new Date(
                    application_data.state === "SCREENING"
                      ? application_data.screened_at
                      : application_data.state === "GATHERING"
                      ? application_data.created_at
                      : application_data.state === "FUNDED"
                      ? application_data.funded_at
                      : application_data.state === "QUALIFIED"
                      ? application_data.qualified_at
                      : application_data.state === "DISQUALIFIED"
                      ? application_data.disqualified_at
                      : application_data.state === "EXECUTION"
                      ? application_data.executed_at
                      : application_data.state === "COMPLETED"
                      ? application_data.completed_at
                      : application_data.state === "CANCELED"
                      ? application_data.canceled_at
                      : ""
                  ).toDateString()
                }}
              </div>
            </div>
          </div>

          <div class="app-status mb-3">
            Application submitted on
            {{ new Date(application_data.created_at).toDateString() }}
          </div>

          <div v-if="application_data.state !== 'CANCELED' && application_data.state !== 'COMPLETED'">
            <div class="need-updates" v-if="false">Need Updates</div>
            <b-dropdown text="Actions" class="application-details-actions">
              <b-dropdown-item @click="changeStatus"> Change Status</b-dropdown-item>
              <b-dropdown-item
                v-if="['GATHERING', 'SCREENING'].includes(application_data.state)"
                @click="ApplicationFileUploadModalShow">
                Upload File
              </b-dropdown-item>
              <b-dropdown-item
                v-if="['QUALIFIED', 'DISQUALIFIED', 'COMPLETED', 'FUNDED'].includes(application_data.state)"
                @click="shareApplication">
                Share application
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  [
                    'GATHERING',
                    'QUALIFIED',
                    'DISQUALIFIED',
                    'COMPLETED',
                    'EXECUTION',
                    'FUNDED',
                    'SCREENING',
                    'COMPLETED',
                    'CANCELED',
                  ].includes(application_data.state)
                "
                @click="addNotification">
                Add Notification
              </b-dropdown-item>
              <b-dropdown-item
                v-if="['QUALIFIED', 'EXECUTION', 'FUNDED', 'COMPLTEED'].includes(application_data.state)"
                @click="changeRating">
                Change Rating
              </b-dropdown-item>
            </b-dropdown>

            <!-- <div>
              <base-button
                  style_variant="secondary"
                  cus_style="font-size: 15px; font-family: Roboto_Medium;background-color:transparent;"
                  @onClick="shareApplication"
                >
                  Share !
                </base-button>
                <base-button
                  style_variant="secondary"
                  cus_style="font-size: 15px; font-family: Roboto_Medium;background-color:transparent;"
                  @onClick="changeStatus"
                >
                  CHANGE STATUS
                </base-button>
            </div> -->
          </div>
        </div>
      </div>
      <b-row style="padding: 0rem 0.5rem">
        <b-col style="padding: 0rem 0.25rem" lg="3" sc="6">
          <div class="block" :class="performerClass">
            <div class="top-label">SCREENING SCORE</div>
            <div class="middle-label">{{ application_data.score }}</div>
            <div class="bottom-label">{{ application_data.discount }}% discount rate</div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem" lg="3" sc="6">
          <div class="block" style="background-color: #dfddd9; color: #741b47">
            <div class="top-label">FACILITY</div>
            <div class="middle-label">
              <span v-html="$amount(application_data.cost_price)"></span>
            </div>
            <div class="bottom-label">
              Sale Price:
              <span v-html="$amount(application_data.sale_price?.toFixed(2))"></span>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem" lg="3" sc="6">
          <div class="block" style="background-color: #dfddd9; color: #741b47">
            <div class="top-label">PAID</div>
            <div class="middle-label">
              <span v-html="$amount(application_data.paid?.toFixed(2))"></span>
              ({{ application_data.progress }} %)
            </div>
            <div class="bottom-label">
              <b-progress height="10px" style="margin: 5px 0px; width: 75%">
                <b-progress-bar style="background-color: #741b47" :value="application_data.progress"></b-progress-bar>
              </b-progress>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem" lg="3" sc="6">
          <div class="block" style="background-color: #dfddd9; color: #741b47">
            <div class="top-label">OUTSTANDING</div>
            <div class="middle-label">
              <span v-html="$amount((application_data.sale_price - application_data.paid).toFixed(2))"></span>
            </div>
            <div class="bottom-label">{{ application_data.scheduled }} remaining repayments</div>
          </div>
        </b-col>
      </b-row>

      <div v-if="!isEdit" class="content-table">
        <b-container class="filter-container">
          <b-row>
            <b-col md="11" class="flex-container">
              <div
                v-for="(tab, index) in tabs"
                :key="index"
                @click="selectTab(tab.value)"
                :class="['tab', selectedTab === tab.value ? 'active-tab' : '']">
                {{ tab.label }}
              </div>
            </b-col>
            <b-col v-if="selectedTab === 1" md="1" class="d-flex align-items-center justify-content-end">
              <div class="action-container" @click="edit">
                <img :src="'/images/edit.png'" :alt="'edit'" height="15px" width="15px" />
              </div>
            </b-col>
          </b-row>
        </b-container>
        <RepaymentsSchedule :application_id="application_id" v-if="selectedTab === 0" />
        <!-- <Insights v-if="selectedTab === 1" /> -->
        <DetailData v-if="selectedTab === 1" :application_data="application_data" />
        <SubmittedApplications v-if="selectedTab === 6" :application_id="application_id" :focusedId="focusedId" />
        <FinancialData v-if="selectedTab === 7" :application_id="application_id" :focusedId="focusedId" />
        <Scores v-if="selectedTab === 8" :application_id="application_id" @handleLinks="handleLinksScores" />

        <Charts
          v-if="selectedTab === 2 && application_data && application_data.charts && application_data.charts.length"
          :app_data="chartData"
          :isDashboard="false" />
        <cohorts-arr
          v-if="selectedTab === 3 && application_data && application_data.cohorts && application_data.cohorts.length"
          :app_data="application_data" />

        <cohorts-logos
          v-if="selectedTab === 3 && application_data && application_data.cohorts && application_data.cohorts.length"
          :app_data="application_data" />
        <!-- <documents v-if="selectedTab === 2" /> -->
      </div>
      <div v-if="isEdit" class="edit-data-container">
        <EditDetailData @close-edit-mode="edit" :application_data="application_data" />
      </div>
    </div>
    <change-status-modal v-if="application_data" :application_data="application_data" @refresh-page="query" />
    <change-charts-modal
      v-if="application_data != null"
      :application_data="application_data"
      @refresh-page="handleChartSubmit" />
    <share-application-modal
      v-if="application_data != null"
      :application_data="application_data"
      @refresh-page="query" />
    <ApplicationFileUploadModal :application_id="application_id" @refresh-page="query" />

    <new-notification-modal :application_id="application_id" @new-notification.once="reportResult" />

    <change-bucket-rating-modal
      v-if="application_data != null"
      :application_id="application_id"
      :unit="application_data.bucket_unit_economics"
      :churn="application_data.bucket_churn"
      :growth="application_data.bucket_growth"
      :efficiency="application_data.bucket_efficiency"
      @bucket-rate-changed.once="bucketRatingChanged" />

    <info-modal :text="infoMessage" />
  </div>
</template>

<script>
import ApplicationsService from "../../services/applications.service";
import CompanyService from "../../services/company.service";
import EnumeratesService from "../../services/enumerates.service";

import RepaymentsSchedule from "./RepaymentsSchedule.vue";
import DetailData from "./DetailData.vue";
import EditDetailData from "./EditDetailData.vue";
import Charts from "../../ui-components/application/Charts.vue";
import CohortsArr from "../../ui-components/application/CohortsArr.vue";
import CohortsLogos from "../../ui-components/application/CohortsLogos.vue";
import SubmittedApplications from "./SubmittedApplications.vue";
import FinancialData from "./FinancialData.vue";
import Scores from "./Scores.vue";

import ChangeStatusModal from "../../components/common/ChangeStatusModal.vue";
import ChangeChartsModal from "@/components/common/ChangeChartsModal.vue";
import ShareApplicationModal from "../../components/common/ShareApplication.vue";
import NewNotificationModal from "../../components/common/NewNotificationModal.vue";
import ChangeBucketRatingModal from "@/components/common/ChangeBucketRatingModal.vue";
import ApplicationFileUploadModal from "@/components/common/ApplicationFileUploadModal.vue";
import InfoModal from "@/components/common/InfoModal.vue";

export default {
  components: {
    RepaymentsSchedule,
    DetailData,
    EditDetailData,
    Charts,
    CohortsArr,
    CohortsLogos,
    ChangeStatusModal,
    ChangeChartsModal,
    ShareApplicationModal,
    NewNotificationModal,
    ApplicationFileUploadModal,
    SubmittedApplications,
    InfoModal,
    FinancialData,
    Scores,
    ChangeBucketRatingModal,
  },
  data() {
    return {
      selectedTab: 0,
      isEdit: false,
      tabs: [
        { label: "Repayments Schedule", value: 0 },
        { label: "Company Details", value: 1 },
        { label: "Data Analysis", value: 6 },
        { label: "Financials", value: 7 },
        { label: "Scores", value: 8 },
      ],
      application_id: null,
      querying: false,
      application_data: null,
      error: null,
      countries: [],
      chartData: null,
      focusedId: null,
      infoMessage: null,
    };
  },
  watch: {
    application_id(newId, oldId) {
      this.application_id = newId;
    },
  },
  methods: {
    reportResult(success) {
      this.infoMessage = success ? "Update added successfully" : "Operation failed!, please try again later";
      this.$bvModal.show("info-modal");
    },

    bucketRatingChanged(success) {
      this.infoMessage = success ? "Bucket rating changed successfully!" : "Operation failed!, please try again later";
      this.$bvModal.show("info-modal");
    },
    handleChartSubmit() {
      this.application_data = null;
      this.query();
    },
    handleLinksScores(id, type) {
      switch (type) {
        case "financial":
          this.focusedId = id;
          this.selectedTab = 7;
          break;
        case "submit":
          this.focusedId = id;
          this.selectedTab = 6;

          break;
        default:
          break;
      }
    },
    chartFormatterData(data) {
      let formattedCharts = JSON.parse(JSON.stringify(data));
      let result = formattedCharts.map((item) => {
        switch (item.type.toLowerCase()) {
          case "area":
            item.type = "line";
            item.fill = "origin";
            item.stacked = null;
            item.basicColor = "#93c7d6";

            break;
          case "column":
            item.type = "bar";
            item.fill = null;
            item.stacked = null;
            item.basicColor = "#93c7d6";

            break;
          case "stacked-column":
            item.type = "bar";
            item.fill = true;
            item.stacked = true;
            item.basicColor = null;

            break;
          default:
            item.type = item.type.toLowerCase();
            item.fill = null;
            item.stacked = null;
            item.basicColor = "#93c7d6";
        }
        return item;
      });

      return result;
    },
    changeCharts() {
      this.$bvModal.show("change-charts-modal");
    },
    changeStatus() {
      this.$bvModal.show("change-status-modal");
    },
    shareApplication() {
      this.$bvModal.show("share-application-modal");
    },

    addNotification() {
      this.$bvModal.show("new-notification-modal");
    },

    changeRating() {
      this.$bvModal.show("change-bucket-rating-modal");
    },
    ApplicationFileUploadModalShow() {
      this.$bvModal.show("ApplicationFileUploadModal");
    },
    goBack() {
      this.$router.back();
    },
    selectTab(index) {
      this.selectedTab = index;
    },
    edit() {
      this.isEdit = !this.isEdit;
      const element = document.getElementById("content-container");
      if (element) {
        element.scrollTop = 0;
      }
      this.query();
    },
    query() {
      if (this.$route.params.id) {
        this.application_id = this.$route.params.id;
        this.querying = true;

        ApplicationsService.getApplicationDetails(this.$route.params.id)
          .then((data) => {
            this.application_data = data;
            if (data.charts.length && !this.tabs.find((each) => each.label === "Insights"))
              this.tabs.push({ label: "Insights", value: 2 });
            if (data.cohorts.length && !this.tabs.find((each) => each.label === "Cohorts"))
              this.tabs.push({ label: "Cohorts", value: 3 });
          })
          .catch(({ message }) => {
            this.error = message ? message : "There was an error loading application.";
          })
          .finally(() => {
            if (this.application_data.company.id) {
              CompanyService.get(this.application_data.company.id)
                .then((data) => {
                  this.application_data["company"] = data;

                  if (this.application_data["company"].country && this.countries.length)
                    this.application_data["company"].country = this.countries.find(
                      (each) => each.id === this.application_data["company"].country
                    ).name;

                  this.chartData = this.chartFormatterData(this.application_data.charts);
                })
                .catch(({ message }) => {
                  this.error = message ? message : "There was an error loading company.";
                })
                .finally(() => {
                  this.querying = false;
                });
            } else this.querying = false;
          });
      }
    },
  },
  mounted() {
    this.querying = true;
    EnumeratesService.countries()
      .then((data) => {
        if (data && data.length)
          this.countries = data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        this.querying = false;
        this.query();
      });
  },
  computed: {
    performerClass() {
      const score = this.application_data.score?.toLowerCase();
      return {
        "low-performer": ["deficient"].includes(score),
        "high-performer": ["very-good", "good", "excellent"].includes(score),
        "moderate-performer": ["need-improvement"].includes(score),
      };
    },
  },
};
</script>

<style lang="scss" src="./scss/details.scss"></style>
<style scoped>
#company-detail-container {
  padding: 25px 45px;
}

#company-detail-container .back-link {
  font-size: 13px;
  color: #8f8b8d;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#company-detail-container .com-avatar {
  margin-right: 0.5rem;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #ebdfe5;
  color: #260b19;
  font-size: 24px;
  font-family: Roboto_Medium;
}

#company-detail-container .com-avatar img {
  border-radius: 50%;
}

#company-detail-container .com-name {
  font-size: 18px;
  font-family: Roboto_Bold;
  color: #260b19;
}

#company-detail-container .com-place {
  font-size: 15px;
  color: #8f8b8d;
  margin: 0.2rem 0rem;
}

#company-detail-container .com-email {
  font-size: 15px;
  text-decoration: underline;
  color: #741b47;
}

#company-detail-container .com-state {
  font-size: 11px;
  font-family: Roboto_Bold;
  border-radius: 5px;
  padding: 0rem 0.2rem;
}

#company-detail-container .app-status {
  font-size: 12px;
  font-family: Roboto_Italic;
  color: #5e595c;
  margin: 0.2rem 0rem;
}

#company-detail-container .need-updates {
  font-size: 15px;
  font-family: Roboto_Regular;
  color: #741b47;
  text-decoration: underline;
  width: 160px;
  margin-right: 10px;
  cursor: pointer;
}

#company-detail-container .block {
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

#company-detail-container .top-label {
  font-size: 14px;
  font-family: Roboto_Medium;
}

#company-detail-container .middle-label {
  font-size: 16px;
  font-family: Roboto_Bold;
}

#company-detail-container .bottom-label {
  font-size: 13px;
}

#company-detail-container .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

#company-detail-container .flex-container {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}

#company-detail-container .tab {
  color: #8f8b8d;
  font-size: 15px;
  font-family: Roboto_Medium;
  margin-right: 2rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

#company-detail-container .tab:hover {
  border-bottom: 2px solid #8f8b8d;
}

#company-detail-container .active-tab {
  color: #741b47;
  border-bottom: 2px solid #741b47;
}

#company-detail-container .action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

#company-detail-container .edit-data-container {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}
</style>
