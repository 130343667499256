import ResourceService from "./resource.service";
import Vue from "vue";

class HelpService extends ResourceService {
  static get resource() {
    return "admin";
  }

  static getHelpData(params) {
    return HelpService.get("help", params);
  }
}


export default HelpService;
