<template>
  <div id="detail-container">
    <b-row>
      <b-col lg="6" sm="12">
        <base-input
          label="Company Name"
          type="text"
          :value="application_data.company.name"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Work Email Address"
          type="text"
          :value="application_data.company.email"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Mobile Phone Number"
          type="text"
          :value="application_data.company.phone"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Company Website URL"
          type="text"
          :value="application_data.company.website"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="HQ Location"
          type="text"
          :value="application_data.hq_location"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Place of Operations"
          type="text"
          :value="application_data.places_of_operation.map((obj) => obj.name).join(', ')"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Date of Establishment"
          type="text"
          :value="new Date(application_data.company.established_at).toDateString()"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Company Business Type"
          type="text"
          :value="application_data.company.types.join(',')"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Subscription Management Tool"
          type="text"
          :value="application_data.company.platforms.join(',')"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Total raised amount"
          type="text"
          :value="application_data.raised_amount"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Average Annual Recurring Revenue (ARR)"
          type="text"
          :value="application_data.arr"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Requested Amount From Capifly"
          type="text"
          :value="application_data.requested"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Number of Institutional Investor"
          type="text"
          :value="application_data.institutional_investors"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Number of Investment Rounds to Date"
          type="text"
          :value="application_data.investments"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Number of Active Contracts"
          type="text"
          :value="application_data.contracts"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Shariah Compliant Facility Preference"
          type="text"
          :value="this.shariah_compliant_txt"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Stage"
          type="text"
          :value="application_data?.stage"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["application_data"],
  computed: {
    getInputStyle() {
      return `text-align:right;color:#8F8B8D;font-family:Roboto_Italic;font-size:13px;`;
    },
  },
  data() {
    return {
      shariah_compliant_txt: "",
    };
  },
  mounted() {
    if (this.application_data.shariah_compliant == null) this.shariah_compliant_txt = "NA";
    else this.shariah_compliant_txt = this.application_data.shariah_compliant ? "YES" : "NO";
  },
};
</script>

<style scoped>
#detail-container {
  padding: 1rem;
}
</style>
