<template>
  <b-modal id="change-charts-modal" size="lg" centered hide-header hide-footer @hidden="reset_files">
    <div class="modal-title">Change Application's Charts</div>
    <validation-observer ref="observer">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <div v-if="2 === 2" key="2">
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Facility Amount" rules="required" v-slot="{ errors }">
              <base-input
                label="Facility Amount (Up to)"
                placeholder="Enter Facility Amount (Up to)"
                type="number"
                :min="0"
                field="fundInput"
                :value="fundInput"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Application Scoring" rules="required" v-slot="{ errors }">
              <base-select
                label="Application Scoring"
                :options="scoringOptions"
                field="applicationScoring"
                :value="applicationScoring"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-select>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="status == 3">
          <b-col lg="6" sm="12">
            <validation-provider name="Notes" v-slot="{ errors }">
              <base-input
                label="Notes"
                placeholder="Enter Notes"
                type="text"
                field="notes"
                :value="notes"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider name="Upload File" v-slot="{ errors }">
              <base-input
                label="Upload Insights File"
                :placeholder="'Leave it blank if you don’t want to change'"
                type="file"
                accept=".csv"
                field="uploadedFile"
                :value="uploadedFile && uploadedFile.name ? uploadedFile.name : ''"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"></base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <base-input
              label="Upload Cohort File"
              :placeholder="'Upload your cohort file here'"
              type="file"
              accept=".csv"
              field="uploadedCohortFile"
              :value="uploadedCohortFile && uploadedCohortFile.name ? uploadedCohortFile.name : ''"
              @change-value="setValue"
              :disabled="loading"></base-input>
          </b-col>
        </b-row>
        <draggable
          @start="drag = true"
          @end="
            reorder_charts();
            drag = false;
          "
          :list="charts"
          class="draggable-list">
          <b-row v-for="(chart, index) in charts" :key="index" :class="{ [`cursor-grabbing`]: drag }">
            <b-col lg="4" sm="12">
              <validation-provider :name="`Chart ${index + 1}`" rules="required" v-slot="{ errors }">
                <base-select
                  :label="`Chart ${index + 1}`"
                  :options="getChartOptions(chart)"
                  field="chart_id"
                  :value="chart.id"
                  @change-value="(val, field) => setChartValue(val, field, index)"
                  :disabled="loading"
                  :is_invalid="errors && errors.length"
                  :helper_text="errors && errors.length ? errors[0] : ''"></base-select>
              </validation-provider>
            </b-col>
            <b-col lg="4" sm="12">
              <base-select
                :options="performanceOptions"
                :cus_style="chart.color ? `background-color:${chart.color};color:#260B19;` : ''"
                style="font-size: 12px; font-family: Roboto_Medium; margin-top: 1.8rem"
                @change-value="(val, field) => changeChartLabelColor(val, field, index)"
                :value="chart.pindex"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''">
              </base-select>
            </b-col>
            <b-col lg="2" sm="12">
              <base-button
                style_variant="secondary"
                cus_style="font-size: 12px; font-family: Roboto_Medium;margin-top:1.8rem"
                :disabled="charts.length === 1"
                @onClick="removeChart(index)">
                REMOVE
              </base-button>
            </b-col>
            <!-- //TODO:  Change this; "row" inside "row" is not a good idea-->
            <b-row v-for="(insight, idx) in chart.insights" :key="idx" lg="10" sm="10">
              <b-col lg="8">
                <base-input
                  type="text"
                  :field="chart_insights"
                  :value="chart.insights[idx]"
                  @change-value="(val, field) => setInsight(chart, idx, val)"
                  lg="8"></base-input>
              </b-col>
              <b-col lg="2" @click="deleteInsight(chart, idx)"> x </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" sm="5" @click="addInsight(chart)"> + </b-col>
            </b-row>
          </b-row>
        </draggable>
        <b-row>
          <b-col offset-md="4" md="4">
            <base-button
              style_variant="secondary"
              cus_style="font-size: 12px; font-family: Roboto_Medium;margin-bottom:1rem"
              :disabled="loading"
              @onClick="addChart">
              ADD CHART
            </base-button>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col offset-lg="8" lg="4" offset-sm="6" sm="6" class="d-flex align-items-center">
          <base-button
            style_variant="primary"
            cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
            :loading="loading"
            :disabled="loading || !status"
            @onClick="submit">
            SUBMIT
          </base-button>
          <base-button
            style_variant="secondary"
            cus_style="font-size: 15px; font-family: Roboto_Medium"
            :disabled="loading"
            @onClick="closeModal">
            CANCEL
          </base-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate/dist/vee-validate.full";

import ApplicationsService from "../../services/applications.service";
import EnumeratesService from "../../services/enumerates.service";
import ChartsService from "../../services/charts.service";
import draggable from "vuedraggable";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "ChangeChartsModal",
  components: {
    ValidationObserver,
    ValidationProvider,
    draggable,
  },
  emits: ["refresh-page"],
  props: ["application_data"],
  data() {
    return {
      error: null,
      errors: null,
      loading: false,
      scoringOptions: [],
      chartList: [],
      status: null,
      fundInput: null,
      applicationScoring: null,
      notes: null,
      fileType: null,
      uploadedFile: null,
      uploadedCohortFile: null,
      repaymentSchedule: null,
      chart_id: null,
      chart_insights: null,
      chart_label: null,
      chart_color: null,
      charts: [{ id: null, label: null, color: null }],
      drag: false,
      performanceOptions: [
        { id: null, label: "Choose" },
        { id: "1", label: "Low performing metric" },
        { id: "2", label: "Moderate performing metric" },
        { id: "3", label: "High performing metric" },
      ],
    };
  },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      // Remove empty insights
      this.removeEmptyInsights(this.charts);

      let payload = {
        score: this.applicationScoring,
        gist: this.uploadedFile ? await toBase64(this.uploadedFile) : null,
        cohort: this.uploadedCohortFile ? await toBase64(this.uploadedCohortFile) : null,
        charts: this.charts,
        max_allowed: this.fundInput,
      };

      if (this.status === 3) {
        payload["notes"] = this.notes;
      }

      if (this.status) {
        if (this.application_data) {
          this.loading = true;
          ApplicationsService.updateApplication(this.application_data.id, payload)
            .then(() => {
              this.$emit("refresh-page");
              this.closeModal();
            })
            .catch(({ message }) => {
              this.error = message ? message : "There was an error updating status.";
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },

    removeEmptyInsights(charts) {
      for (let chartIdx in charts) {
        let chart = charts[chartIdx];
        let insightIdx = 0;
        while (insightIdx < chart.insights.length) {
          if (chart.insights[insightIdx] == "") chart.insights.splice(insightIdx, 1);
          else insightIdx++;
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("change-charts-modal");
    },
    setChartValue(val, field, index) {
      if (field === "chart_id") this.charts[index]["id"] = val;
      if (field === "chart_label") this.charts[index]["label"] = val;
      if (field === "chart_color") this.charts[index]["color"] = val;
      this[field] = val;
    },
    setValue(val, field) {
      this[field] = val;
    },
    setInsight(chart, idx, val) {
      chart.insights[idx] = val;
    },
    deleteInsight(chart, idx) {
      if (chart.insights) chart.insights.splice(idx, 1);
    },

    addInsight(chart) {
      if (chart.insights) {
        chart.insights.push("");
      } else chart.insights = [""];
    },
    getChartOptions(chart) {
      return [
        { id: null, label: "Select Any" },
        ...this.chartList
          .filter((rec) => {
            if (rec && rec.id) {
              const chart_found = this.charts.find((chart_rec) => {
                return (
                  chart_rec &&
                  chart_rec.id &&
                  (!chart.id || (chart.id && chart_rec.id !== chart.id)) &&
                  chart_rec.id === rec.id
                );
              });
              if (chart_found) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          })
          .map((each) => {
            return {
              id: each.id,
              label: `${each.name} ${each.symbol ? `${each.symbol}` : ``} ${each.segment ? `(${each.segment})` : ``}`,
            };
          }),
      ];
    },

    addChart() {
      let maxOrder = this.charts
        .map((item) => item.order)
        .reduce((prev, curr) => {
          return Math.max(prev, curr);
        }, 0);
      let newChartOrder = maxOrder + 1;
      this.charts.push({ id: null, label: null, color: null, order: newChartOrder, insights: [] });
    },
    removeChart(index) {
      this.charts.splice(index, 1);
    },
    reset_files() {
      this.uploadedFile = null;
      this.uploadedCohortFile = null;
    },
    reset() {
      this.error = null;
      this.loading = false;
      this.fundInput = null;
      this.applicationScoring = null;
      this.notes = null;
      this.fileType = null;
      this.uploadedFile = null;
      this.uploadedCohortFile = null;
      this.chart_id = null;
      this.chart_label = null;
      this.chart_color = null;
      this.charts = [{ id: null, label: null, color: null }];
    },
    reorder_charts() {
      this.charts.forEach((chart, index) => {
        chart.order = index;
      });
    },
    changeChartLabelColor(val, field, index) {
      this.charts = this.charts.map((item) => this.addPerformance(item));
      const color = val == 1 ? "#FAE3D4" : val == 2 ? "#D7D7D7" : "#E8DFF5";
      const label = this.performanceOptions[val]?.label?.replace(/\([^)]+\)\s/, "");
      if (color && label) {
        this.charts[index]["color"] = color;
        this.charts[index]["label"] = label;
      }
    },
    addPerformance(item) {
      if (item.pindex || !item.label) return item;

      item.pindex = 0;

      // 1. Exact match for High and Low
      const performance = this.performanceOptions.find((perf) => {
        return perf.label === item.label;
      });
      if (performance) {
        item.pindex = performance.id;
      } else {
        // For Moderate (Low) and Moderate (High) match the first word
        if (item.label.startsWith("Moderate")) {
          item.pindex = item.color == "#FAE3D4" ? 2 : 3;
        } else {
          this.performanceOptions.push({
            id: this.performanceOptions.length,
            label: item.label + "         (Legacy)",
          });
          item.pindex = this.performanceOptions.length - 1;
        }
      }
      return item;
    },
  },
  watch: {
    application_data(newValue, oldValue) {
      this.charts = newValue?.charts.map((item) => this.addPerformance(item));
    },
  },
  mounted() {
    this.status = this.application_data.state == "QUALIFIED" ? 2 : 3;
    this.loading = true;
    this.fundInput = this.application_data?.max_allowed;
    this.applicationScoring = this.application_data?.score;
    this.charts = this.application_data?.charts.map((item) => this.addPerformance(item));
    this.notes = this.application_data?.notes;

    EnumeratesService.scores({ type: "APPLICATION" })
      .then((data) => {
        if (data && data.length) {
          this.scoringOptions = data.map((item) => {
            return {
              id: item.id,
              label: item.name,
            };
          });
          this.scoringOptions = this.scoringOptions.filter((item) => {
            return (
              (this.status == 2 && item.id != "NEED-IMPROVEMENT") || (this.status == 3 && item.id == "NEED-IMPROVEMENT")
            );
          });
        }
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        ChartsService.query()
          .then(({ objects }) => {
            this.chartList = objects;
          })
          .catch(({ message }) => {
            this.error = message;
          })
          .finally(() => {
            this.loading = false;
          });
      });
  },
};
</script>

<style scoped>
#change-charts-modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

.draggable-list > div {
  cursor: pointer;
}
</style>
