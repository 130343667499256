import Vue from "vue";

class AuthService {
  static login(payload) {
    const url = "admin/login";

    return Vue.axios.post(url, payload);
  }

  static forgotPassword(payload) {
    const url = "admin/forgot-password";

    return Vue.axios.post(url, payload);
  }

  static resetPassword(slug, payload) {
    const url = `admin/reset-password/${slug}`;

    return Vue.axios.post(url, payload);
  }

  static verifyResetPasswordSlug(slug) {
    const url = `admin/reset-password/${slug}`;

    return Vue.axios.get(url);
  }
}

export default AuthService;
