<template>
  <div id="onboardingSubmit">
    <div>
      <ul class="nav nav-tabs navs">
        <li class="nav-item  cursor">
          <a class="nav-link m-0 " aria-current="page" :class="{ active: selectedTab === 'pending' }"
            @click="selectTab('pending')">
            Pending</a>
          <!-- </li>
        <li class="nav-item cursor"> 
          <a
            class="nav-link"
            @click="selectTab('accepted')"
            :class="{ active: selectedTab === 'accepted' }"
          >
            Accepted</a
          >
        </li> -->
        <li class="nav-item cursor">
          <a class="nav-link" @click="selectTab('rejected')" :class="{ active: selectedTab === 'rejected' }">
            Rejected
          </a>
        </li>
        <li class="nav-item cursor">
          <a class="nav-link" @click="selectTab('ineligible')" :class="{ active: selectedTab === 'ineligible' }">
            Ineligible
          </a>
        </li>
        <li class="nav-item cursor">
          <a class="nav-link" @click="selectTab('deleted')" :class="{ active: selectedTab === 'deleted' }">
            Deleted
          </a>
        </li>
        <li class="nav-item cursor">
          <a class="nav-link" @click="selectTab('applied')" :class="{ active: selectedTab === 'applied' }">
            Applied 1'st step
          </a>
        </li>
        <!-- <li class="nav-item cursor">
          <a
            class="nav-link"
            @click="selectTab('verified')"
            :class="{ active: selectedTab === 'verified' }"
          >
            Email verified
          </a>
        </li> 
        
        <li class="nav-item cursor">
          <a
            class="nav-link"
            @click="selectTab('applied')"
            :class="{ active: selectedTab === 'applied' }"
          >
            Email Not verified
          </a>
        </li>          -->
        <!-- <li class="nav-item cursor">
          <a
            class="nav-link"
            @click="selectTab('eligible')"
            :class="{ active: selectedTab === 'eligible' }"
          >
            Eligible-No data
          </a>
        </li>  
        <li class="nav-item cursor">
          <a
            class="nav-link"
            @click="selectTab('no-financial')"
            :class="{ active: selectedTab === 'no-financial' }"
          >
            No financial
          </a>
        </li> -->
      </ul>
    </div>

    <div class="top-container">
      <div>
        <button class="refresh-btn" @click="query">
          <img src="/images/refresh.svg" alt="back-icon" />Refresh
        </button>
      </div>

      <div class="pagination-container" v-if="total_count">
        <base-pagination :active_page="active_page" :total_page="total_page" :total_count="total_count"
          :page_size="page_size" @set-page="setPage" />
      </div>
    </div>
    <div v-if="querying" class="loader-overlay">
      <common-loading />
    </div>
    <div class="content-table" v-if="data && data.length > 0">
      <div class="table-container">
        <b-row class="table-title">
          <b-col class="table-title-field">
            <div class="table-title-text">{{ ['applied', 'verified'].includes(selectedTab) ? "Name" : "Company Name" }}
            </div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">{{ ['applied', 'verified'].includes(selectedTab) ? "Email" : "ARR last year"
              }}</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">{{ ['applied', 'verified'].includes(selectedTab) ? "Phone" : "HQ country" }}
            </div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field" v-if="!['applied', 'verified'].includes(selectedTab)">
            <div class="table-title-text">Working locations</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field" v-if="!['applied', 'verified'].includes(selectedTab)">
            <div class="table-title-text">Industry</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Application date</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>

          <b-col class="table-title-field" v-if="selectedTab == 'pending'">
            <div class="table-title-text">Data Available</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>

          <b-col class="table-title-field" v-if="['applied', 'deleted'].includes(selectedTab)">
            <div class="table-title-text">Email Verified</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field" v-if="selectedTab == 'pending'">
            <div class="table-title-text">Actions</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
        </b-row>
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <b-row class="table-data" v-for="(each, index) in data" :key="index" @click="navigateToApplication(each.id)">
          <b-col class="com-info">
            <div class="com-name" @click.stop="navigateToApplication(each.id)">
              {{
            ['applied', 'verified'].includes(selectedTab) ? each.name :
              (each.eligibility_step.businessName ? each.eligibility_step.businessName : "no businessName")
          }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country">
              {{
              ['applied', 'verified'].includes(selectedTab) ? each.email :
                (each.eligibility_step.ARRYearlyRevenue ? each.eligibility_step.ARRYearlyRevenue : null)
            }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country">
              {{
              ['applied', 'verified'].includes(selectedTab) ? each.phoneNumber :
                (each.eligibility_step.countryOfIncorporation ? each.eligibility_step.countryOfIncorporation : null)
            }}
            </div>
          </b-col>
          <b-col v-if="!['applied', 'verified'].includes(selectedTab)">
            <div class="com-country">
              {{
            each.eligibility_step.placeOfOperation ? getNamesFromArray(each.eligibility_step.placeOfOperation) : null
          }}
            </div>
          </b-col>
          <b-col v-if="!['applied', 'verified'].includes(selectedTab)">
            <div class="com-country">
              {{
            each.eligibility_step.businessType
              ? getNamesFromArray(each.eligibility_step.businessType)
              : "no businessType"
          }}
            </div>
          </b-col>

          <b-col>
            <div class="com-country">
              {{ each.created_at ? new Date(each.created_at).toISOString().substring(0, 10) : 'N/A' }}
            </div>
          </b-col>

          <b-col v-if="selectedTab == 'pending'">
            <div class="com-country">
              {{ check_data(each.stage) }}
            </div>
          </b-col>

          <b-col v-if="['applied', 'deleted'].includes(selectedTab)">
            <div class="com-country">
              {{ each.stage == 'APPLIED' ? 'NO' : 'YES' }}
            </div>
          </b-col>

          <b-col v-if="selectedTab == 'pending'">
            <div class="com-country position-rel">
              <div class="action-container" @click.stop="openMenu(index)">
                <img :src="openMenuFlag === index
            ? '/images/eye-icon.png'
            : '/images/dot-icon.png'
            " :alt="openMenuFlag === index ? 'eye-icon' : 'dot-icon'" />
              </div>
              <div class="action-menu" v-if="openMenuFlag === index & selectedTab == 'pending'">
                <div class="accept action-menu-item" @click.stop="handleActions(each, ACTIONS.ACCEPT)">
                  {{ ACTIONS.ACCEPT }}
                </div>
                <div class="declined action-menu-item" @click.stop="handleActions(each, ACTIONS.REJECT)">
                  {{ ACTIONS.REJECT }}
                </div>
                <div class="delete action-menu-item" @click.stop="handleActions(each, ACTIONS.DELETE)">
                  {{ ACTIONS.DELETE }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="bottom-container">
      <div class="pagination-container" v-if="total_count">
        <base-pagination :active_page="active_page" :total_page="total_page" :total_count="total_count"
          :page_size="page_size" @set-page="setPage" />
      </div>
    </div>
    <ConfirmationDialog :onSubmit="deleteApplication"
      :text="`Are you sure you want to delete the ${companyName} application?`" />
    <EmailTemplateModal v-if="emailTempData" :data="emailTempData" :emailData="emailData" :onSubmit="handleReject"
      @refresh-page="query" ref="emailModal" />
  </div>
</template>

<script>
import ApplicationsService from "../../services/applications.service";
import { ErrorMessages } from "../../lib/errorMessages";
//TODO: Rename this to ConfirmationModal, and make it global, not just for delete
//TODO: Add text to the loading screen
//TODO: Should add functions/actions for each tab, so can reconsider the application as eligible, or move it from deleted ..etc
//TODO: add search
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import EmailTemplateModal from "@/components/common/EmailTemplateModal.vue";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import OnboardingService from '@/services/onboarding.service';

const ACTIONS = Object.freeze({
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  DELETE: 'Delete'
})

export default {
  name: "onboardingSubmit",
  components: { ConfirmationDialog, EmailTemplateModal },
  data() {
    return {
      selectedTab: "eligible",
      error: null,
      querying: false,
      page_size: 10,
      active_page: 1,
      total_page: 0,
      total_count: 0,
      data: [],
      openMenuFlag: null,
      companyName: null,
      emailTempData: null,
      emailData: null,
      email: null,
      status: null,
      ACTIONS: ACTIONS
    };
  },
  methods: {
    deleteApplication() {
      this.querying = true;
      const payload = {
        email: this.email,
        id: this.target_id,
        action: this.status,
      };

      OnboardingService.actions(this.target_id, this.status, this.email)
        .then(({ data }) => {
          this.query();
        })
        .catch((e) => {
          this.error = ErrorMessages.errorDeletingUser;
        }).finally(() => {
          this.querying = false;
        });
    },

    check_data(stage) {
      if (stage === 'ELIGIBLE') return 'No Data'
      if (stage === 'TRANSACTION') return 'Transactions data only'
      return "Full data";
    },

    getNamesFromArray(objectsArray) {
      if (typeof objectsArray !== "string") {
        return objectsArray?.map((obj) => obj.name).join(", ");
      }
      return objectsArray;
    },
    openMenu(index) {
      this.openMenuFlag = this.openMenuFlag === index ? null : index;
    },
    setPage(page) {
      this.active_page = page;
      this.query()
    },
    navigateToApplication(onboardingId) {
      this.$router.push(`/OnboardingApplication/${onboardingId}`);
    },
    openS3(file) {
      if (!file) return;
      let payload = { file: file };
      ApplicationsService.applicationDownloadOutput(payload)
        .then(({ data }) => {
          if (data) {
            window.open(data, "_blank");
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoading;
        });
    },
    ...mapActions("onboarding", ["updateOnboardingTab"]),
    selectTab(tab) {
      // Do Nothing
      if (tab == this.selectedTab)
        return;

      this.updateOnboardingTab(tab);
      this.selectedTab = tab;
      this.setPage(1)
    },
    query(params = {}) {
      params["page"] = this.active_page || 1;
      params["limit"] = this.page_size;
      params["actionStatus"] = this.selectedTab;

      this.error = null;
      this.data = [];
      this.total_count = 0;

      OnboardingService.applications(params)
        .then((data) => {
          //TODO: move all the error message/alert to the base class
          if (!data || data.code != 0) {
            this.error = ErrorMessages.errorLoading;
          } else {
            data = data.data;
            this.total_count = data.count;
            this.data = data?.objects.map((item) => {
              return {
                id: item.id,
                email: item.email,
                phoneNumber: item.phone_number,
                name: item.name,
                eligibility_step: item.eligibility_step,
                transactions_step: item.transactions_step,
                financials_step: item.financials_step,
                stage: item.stage,
                created_at: item.created_at
              };
            });
            this.total_page = Math.ceil(
              this.data.length / this.page_size
            );
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoading;
        })
        .finally(() => {
          this.querying = false;
        });
    },

    handleActions(item, action) {
      this.openMenuFlag = null;
      this.email = item.email;
      this.target_id = item.id
      this.status = action;
      this.companyName = item?.eligibility_step?.businessName;
      this.action = action

      if (action === ACTIONS.DELETE) {
        this.$bvModal.show("confirmation-modal");
        return;
      }
      if (action === ACTIONS.REJECT) {
        //TODO: this should be in django template !
        (this.emailData = `Dear ${item.name} ,

We regret to inform you that your application has not been approved at this time
Thank you for your interest in Capifly and for the effort you put into your application.

Best regards,
Your Friends at Capifly`),
          (this.emailTempData = item);
        this.$nextTick(() => {
          this.$refs.emailModal.$bvModal.show("email-modal");
        });
        return;
      }

      else if (action == ACTIONS.ACCEPT) {
        this.querying = true;
        OnboardingService.actions(item.id, 'accept', item.email)
          .then(({ data }) => {
            this.query()
          })
          .finally(() => {
            this.querying = false;
          })

      }
    },

    handleReject(emailContent) {
      const payload = {
        id: this.target_id,
        emailContent: emailContent,
        action: 'reject'
      };

      this.querying = true
      OnboardingService.actions(this.target_id, 'reject', emailContent)
        .then(({ data }) => {
          this.querying = false
          this.query();
        })
        .catch((e) => {
          this.error = ErrorMessages.errorUploading;
        })
        .finally(() => {
          this.querying = false;
        });

    }
  },
  watch: {
    total_count(val) {
      this.total_page = Math.ceil(val / this.page_size);
    },
  },
  computed: {
    ...mapGetters("onboarding", ["getOnboardingTab"]),
  },
  mounted() {
    this.selectedTab = this.getOnboardingTab;
    this.querying = true;
    this.query();
  },
};
</script>

<style scoped>
.cursor {
  cursor: pointer;
}

.position-rel {
  position: relative;
}

.action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  z-index: 99999;
}

.action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}

.action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept {
  color: rgb(102, 170, 34);
}

.declined {
  color: #741b47;
}

.delete {
  color: #741b47;
}

.refresh-btn {
  width: 113px;
  height: 36px;
  text-transform: capitalize;
  cursor: pointer;
  background-color: #e9e9ea;
  color: #394044;
  border: 1px solid #e9e9ea;
  padding: 8px 10px 8px 10px;
  gap: 8px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#onboardingSubmit {
  padding: 1rem 1rem;
}

#onboardingSubmit .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}

#onboardingSubmit .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

#onboardingSubmit .table-container {
  padding: 0.5rem;
}

#onboardingSubmit .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

#onboardingSubmit .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}

#onboardingSubmit .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}

#onboardingSubmit .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}

#onboardingSubmit .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

#onboardingSubmit .com-info {
  display: flex;
  align-items: center;
}

#onboardingSubmit .com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
}

#onboardingSubmit .com-country {
  font-size: 13px;
  color: #8f8b8d;
}

#onboardingSubmit .link-s3 {
  font-size: 13px;
  color: blue;
  font-weight: 600;
}

#onboardingSubmit .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}

.nav-tabs .nav-link.active {
  color: #024058;
  background-color: transparent;
  border: none;
  font-size: 16px;
  border-bottom: 5px solid #b4e7e2;
  padding: 8px 0px 8px 0px;
}

.nav-tabs .nav-link {
  color: #394044;
  border-bottom: 5px solid #cdcfd0;
  padding: 8px 0px 8px 0px;
  margin-left: 40px;
}

.active {
  background-color: #daf3f1;
}

.tabs-wrapper.disabled .steps,
.tabs-wrapper.disabled .titles {
  color: #cdcfd0;
}

.navs {
  border-bottom-color: transparent;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}
</style>
