<template>
  <div id="scores">
    <div v-if="!loading">
      <b-alert :show="error ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </div>

    <div v-if="querying">
      <common-loading />
    </div>
    <div class="content-table">
      <div class="table-container">
        <b-row class="table-title">
          <b-col class="table-title-field">
            <div class="table-title-text">Name</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Date</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Output Link</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Output</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Status</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Actions</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Downloads</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Financial Link</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Analysis Link</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
        </b-row>
        <b-alert :show="error ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <div v-if="querying">
          <common-loading />
        </div>
        <b-row class="table-data" v-for="(each, index) in data" :key="index">
          <b-col>
            <div class="com-country">{{ each.label }}</div>
          </b-col>
          <b-col class="com-info">
            <div class="com-name" @click.stop="navigateToApplication(each.application_id)">
              {{ formattedDate(each.date) }}
            </div>
          </b-col>
          <b-col>
            <div :class="each.status != 'SUCCESS' ? 'com-country' : 'link-s3'" @click.stop="openS3(each.output_path)">
              {{ each.status != "SUCCESS" ? "No Output" : "Download File" }}
            </div>
          </b-col>
          <b-col>
            <div :class="each.output ? 'link-s3' : 'com-country'" @click.stop="openShowErrorModal(each.output)">
              {{ each.output ? "show Output" : "No Output" }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country">{{ each.status }}</div>
          </b-col>
          <b-col>
            <div class="com-country position-rel">
              <div class="action-menu">
                <div class="delete action-menu-item" @click="handleActions(each.id)">
                  Delete
                </div>
              </div>
            </div>
          </b-col>

          <b-col>
            <div class="com-country position-rel">
              <div class="action-container" @click.stop="openMenu(index)">
                <img :src="openMenuFlag === index
                    ? '/images/eye-icon.png'
                    : '/images/dot-icon.png'
                  " :alt="openMenuFlag === index ? 'eye-icon' : 'dot-icon'" />
              </div>
              <div class="action-menu" v-if="openMenuFlag === index">
                <div class="action-menu-item" @click.stop="downloadS3(each.id)">
                  Download Score
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="com-country" @click.stop="handleLinks(each.financial_id, 'financial')">
              {{ each.financial_label }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country" @click.stop="handleLinks(each.submit_id, 'submit')">
              {{ each.submit_label }}
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <base-button style_variant="primary" cus_style="font-size:15px" @onClick="generateFinal">
          New Scoring
        </base-button>
      </div>
    </div>
    <ShowErrorModal :data="errorData" />
    <GenerateFinalModal @submission-successful="handleSubmissionSuccessful" />
  </div>
</template>

<script>
import ApplicationsService from "../../services/applications.service";
import { ErrorMessages } from "../../lib/errorMessages";
import ShowErrorModal from "../../components/common/ShowErrorModal.vue";
import GenerateFinalModal from "../../components/common/GenerateFinalModal.vue";
import moment from "moment";

export default {
  name: "scores",
  props: {
    application_id: String,
  },
  components: { ShowErrorModal, GenerateFinalModal },
  data() {
    return {
      error: null,
      querying: false,
      data: [],
      errorData: "",
      loading: false,
      name: null,
      openMenuFlag: false,
    };
  },
  methods: {
    handleLinks(id, type) {
      this.$emit("handleLinks", id, type);
    },
    downloadS3(id) {
      const payload = { scoreId: id };
      const applicationId = this.$route.params.id;

      ApplicationsService.scoresUrl(payload, applicationId)
        .then(({ data }) => {
          if (data) {
            window.open(data, "_blank");
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoading;
        });
    },
    openMenu(index) {
      this.openMenuFlag = this.openMenuFlag === index ? null : index;
    },
    handleSubmissionSuccessful() {
      this.query();
    },
    generateFinal() {
      this.$bvModal.show("generate-final-modal");
    },
    //TODO: this seems  not  working !
    navigateToApplication(application_id) {
      if (application_id === this.$route.params.id) return;
      this.$router.push(`/applications/${application_id}`);
    },

    openS3(file) {
      if (!file) return;
      let payload = { file: file };
      ApplicationsService.applicationDownloadOutput(payload)
        .then(({ data }) => {
          if (data) {
            window.open(data, "_blank");
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoading;
        });
    },
    query() {
      this.error = null;
      this.querying = true;
      this.data = [];
      this.loading = true;

      ApplicationsService.getScores(this.application_id)
        .then(({ data }) => {
          if (!data || data.data.length < 1) {
            this.error = ErrorMessages.noData;
          } else {
            this.data = data?.data.map((item) => {
              return {
                id: item.id,
                application_id: item.application_id,
                date: item.created_at,
                output_path: item.s3_location,
                output: item.output,
                status: item.status,
                label: item.label,
                financial_id: item.financial_id,
                submit_id: item.submit_id,
                financial_label: item.financial_label || "Financial",
                submit_label: item.analysis_label || "Analysis",
              };
            });
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoading;
        })
        .finally(() => {
          this.querying = false;
          this.loading = false;
        });
    },
    formattedDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    openShowErrorModal(data) {
      if (!data) return;
      this.errorData = data;
      this.$bvModal.show("ShowErrorModal");
    },
    handleActions(id) {
      //TODO: On delete should show confirmation dialog
      let url = `${this.application_id}/scores/${id}`;
      ApplicationsService.delete(url)
        .then((res) => {
          this.query();
        })
        .catch((e) => {
          this.error = ErrorMessages.errorDeleting;
        });
    },
  },
  watch: {
    $route(val) {
      if (val) this.query();
    },
  },
  mounted() {
    this.querying = true;
    this.query();
    this.querying = false;
  },
};
</script>

<style scoped>
.link-s3 {
  font-size: 13px;
  color: blue;
  font-weight: 600;
}
.delete {
  color: #741b47;
}

#scores {
  padding: 1rem 1rem;
}

#scores .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}

#scores .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

#scores .table-container {
  padding: 0.5rem;
}

#scores .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

#scores .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}

#scores .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}

#scores .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}

#scores .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

#scores .com-info {
  display: flex;
  align-items: center;
}

#scores .com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
}

#scores .com-country {
  font-size: 13px;
  color: #8f8b8d;
}

#scores .link-s3 {
  font-size: 13px;
  color: blue;
  font-weight: 600;
}

#scores .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
</style>
