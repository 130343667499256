import { render, staticRenderFns } from "./DetailData.vue?vue&type=template&id=e87e6e42&scoped=true"
import script from "./DetailData.vue?vue&type=script&lang=js"
export * from "./DetailData.vue?vue&type=script&lang=js"
import style0 from "./DetailData.vue?vue&type=style&index=0&id=e87e6e42&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e87e6e42",
  null
  
)

export default component.exports