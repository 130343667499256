import { render, staticRenderFns } from "./AddUserModal.vue?vue&type=template&id=0ff5d4ae&scoped=true"
import script from "./AddUserModal.vue?vue&type=script&lang=js"
export * from "./AddUserModal.vue?vue&type=script&lang=js"
import style0 from "./AddUserModal.vue?vue&type=style&index=0&id=0ff5d4ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ff5d4ae",
  null
  
)

export default component.exports