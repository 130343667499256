import AuthService from "../services/auth.service";

import { JWTService, UserService } from "../storage";

export const LOGIN = "login";
export const LOGOUT = "logout";

const AUTHENTICATE = "authenticate";
const DEAUTHENTICATE = "deauthenticate";

export default {
  state: {
    authenticated: !!JWTService.getToken(),
    user: UserService.getUser(),
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
  },
  actions: {
    [LOGIN]({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        AuthService.login(credentials)
          .then(({ data }) => {
            commit(AUTHENTICATE, data);

            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [LOGOUT]({ commit }) {
      commit(DEAUTHENTICATE);
    },
  },
  mutations: {
    [AUTHENTICATE](state, { token, user }) {
      state.authenticated = true;
      state.user = user;

      JWTService.setToken(token);
      UserService.setUser(user);
    },
    [DEAUTHENTICATE](state) {
      state.authenticated = false;
      state.user = {};

      JWTService.removeToken();
      UserService.removeUser();
    },
  },
};
