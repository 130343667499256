var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"main-sidebar"}},[_vm._m(0),_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,staticClass:"tab-container"},[_c('div',{class:[
        'tab',
        _vm.activetab === index ? 'active' : '',
        tab.comming_soon ? 'comming-soon' : '',
      ],style:(tab.comming_soon || tab.notification_count
          ? 'justify-content: space-between'
          : ''),on:{"click":function($event){return _vm.selectTab(tab, index)}}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticClass:"tab-avtar",attrs:{"src":_vm.activetab === index
              ? `/images/${tab.active_icon}`
              : `/images/${tab.inactive_icon}`,"alt":tab.name}}),_c('div',{staticClass:"tab-name"},[_vm._v(_vm._s(tab.name))])]),(tab.comming_soon)?_c('div',{staticClass:"comming-soon-text"},[_vm._v("COMING SOON")]):(tab.notification_count)?_c('div',{staticClass:"notification-count"},[_vm._v(" "+_vm._s(tab.notification_count)+" ")]):_vm._e()])])}),_c('div',{staticClass:"bottom-container"},[_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"avtar-container"},[(_vm.$store.getters.user.image)?_c('img',{attrs:{"src":_vm.$store.getters.user.image,"alt":"avtar"}}):_c('span',[_vm._v(_vm._s(_vm.$store.getters.user.first_name.substring(0, 1)))])]),_c('div',{staticClass:"user-email"},[_vm._v(" "+_vm._s(_vm.$store.getters.user.first_name)+" "+_vm._s(_vm.$store.getters.user.last_name)+" ")])]),_c('div',{staticClass:"link-container"},[_c('div',{staticClass:"link",on:{"click":_vm.logout}},[_vm._v("Log out")]),_c('div',{staticClass:"link",staticStyle:{"margin-left":"1rem"},on:{"click":_vm.settings}},[_vm._v(" Settings ")])])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-container"},[_c('img',{staticClass:"tab-avtar",attrs:{"src":"/images/sidebar-logo.png","alt":"sidebar-logo"}})])
}]

export { render, staticRenderFns }