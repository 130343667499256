<template>
  <div id="applications-container">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="title">Applications</div>
      <div>
        <base-button
          style_variant="primary"
          cus_style="font-size:15px"
          @onClick="openModal()"
        >
          CREATE APPLICATION
        </base-button>
      </div>
    </div>
    <second-application ref="application_list" />
    <new-application @refresh-page="query" />
  </div>
</template>

<script>
import SecondApplication from "./ApplicationList.vue";
import NewApplication from "./NewApplication.vue";

export default {
  name: "Applications-Page",
  components: { SecondApplication, NewApplication },
  methods: {
    openModal() {
      this.$bvModal.show("new-application-modal");
    },
    query() {
      this.$refs.application_list.query();
    },
  },
};
</script>

<style scoped>
#applications-container {
  padding: 70px 60px;
}
#applications-container .title {
  font-size: 18px;
  font-family: Roboto_Medium;
  color: #8f8b8d;
  margin-bottom: 1rem;
}
</style>
