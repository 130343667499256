<template>
  <b-modal id="financial-modal" size="lg" centered hide-header hide-footer>
    <div class="modal-title">Add Monthly Management Account</div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <p>Please upload monthly managment account for 12 months or more using the below format</p>
    <p>Please not that, the calculation will be done for the period included in this file only</p>
    <p>
      <a href="/MonthlyManagementAccount.xlsx">Monthly Management Account Template</a>
    </p>
    <ExcelMapping
      @financial-submission-successful="handleSubmissionSuccessful"
    />
  </b-modal>
</template>

<script>
//TODO: monthly.xlsx template should be uploaded to the S3/Cloudfront as part of the TF
import ExcelMapping from "../../pages/ExcelMapping.vue";

export default {
  name: "financial-modal",
  components: {
    ExcelMapping,
  },
  data() {
    return {
      error: null,
      loading: false,
      label: null,
      showLabel: false,
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("financial-modal");
    },
    handleSubmissionSuccessful() {
      this.$emit("financial-submission-successful");
    },
  },
};
</script>

<style scoped>
 .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}
.cursor-grabbing {
  cursor: grabbing !important;
}

.draggable-list > div {
  cursor: pointer;
}
</style>
