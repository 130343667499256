import StorageService from "./storage.service";

class UserService extends StorageService {
  /**
   * Return the default storage key.
   *
   * @returns {string} The default storage key.
   */
  static get key() {
    return "user";
  }

  /**
   * Return the logged in from the local storage, if any.
   *
   * @returns {{}} The logged in user.
   */
  static getUser() {
    return JSON.parse(sessionStorage.getItem(this.key) || "{}");
  }

  /**
   * Set the logged in the local storage.
   *
   * @param {{}} user The logged in user.
   */
  static setUser(user) {
    sessionStorage.setItem(this.key, JSON.stringify(user || {}));
  }

  /**
   * Remove the logged user from the local storage.
   */
  static removeUser() {
    sessionStorage.removeItem(this.key);
  }
}

export default UserService;
