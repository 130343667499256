<template>
  <div id="edit-detail-container">
    <b-row class="mb-4">
      <b-col lg="7" sm="12" class="title">Edit Company Details</b-col>
      <b-col v-if="false" lg="5" sm="12" class="d-flex align-items-center">
        <base-button
          type="submit"
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
          @onClick="save"
        >
          SAVE CHANGES
        </base-button>
        <base-button
          type="submit"
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
          @onClick="cancel"
        >
          CANCEL
        </base-button>
      </b-col>
    </b-row>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <validation-observer ref="observer">
      <b-row>
        <b-col lg="6" sm="12">
          <validation-provider name="name" rules="required" v-slot="{ errors }">
            <base-input
              label="Company Name"
              type="text"
              field="name"
              :value="name"
              @change-value="setValue"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
          </validation-provider>
        </b-col>
        <b-col lg="6" sm="12">
          <validation-provider
            name="name"
            rules="required|email"
            v-slot="{ errors }"
          >
            <base-input
              label="Work Email Address"
              type="text"
              field="company_work_email"
              :value="company_work_email"
              @change-value="setValue"
              :is_invalid="errors && errors.length"
              helper_text="This field cannot be edited"
              :helper_text_style="getInputStyle"
              readOnly
            ></base-input>
          </validation-provider>
        </b-col>
        <b-col lg="6" sm="12">
          <validation-provider
            name="name"
            :rules="{ regex: /^[2-9]\d{2}[2-9]\d{2}\d{4}$/, required: true }"
            v-slot="{ errors }"
          >
            <base-input
              label="Mobile Phone Number"
              type="text"
              field="phone"
              :value="phone"
              @change-value="setValue"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
          </validation-provider>
        </b-col>
        <b-col lg="6" sm="12">
          <validation-provider name="name" rules="required" v-slot="{ errors }">
            <base-input
              label="Company Website URL"
              type="text"
              field="website"
              :value="website"
              @change-value="setValue"
              :is_invalid="errors && errors.length"
              :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
          </validation-provider>
        </b-col>
        <b-col lg="6" sm="12">
          <base-input
            label="Place of Operations"
            type="text"
            :value="application_data.company.country"
            helper_text="This field cannot be edited"
            :helper_text_style="getInputStyle"
            readOnly
          ></base-input>
        </b-col>
        <b-col lg="6" sm="12">
          <base-input
            label="Date of Establishment"
            type="text"
            :value="
              new Date(application_data.company.established_at).toDateString()
            "
            helper_text="This field cannot be edited"
            :helper_text_style="getInputStyle"
            readOnly
          ></base-input>
        </b-col>
        <b-col lg="6" sm="12">
          <base-input
            label="Company Business Type"
            type="text"
            :value="application_data.company.types.join(',')"
            helper_text="This field cannot be edited"
            :helper_text_style="getInputStyle"
            readOnly
          ></base-input>
        </b-col>
        <b-col lg="6" sm="12">
          <base-input
            label="Subscription Management Tool"
            type="text"
            :value="application_data.company.platforms.join(',')"
            helper_text="This field cannot be edited"
            :helper_text_style="getInputStyle"
            readOnly
          ></base-input>
        </b-col>
        <b-col lg="6" sm="12">
          <base-input
            label="Average Annual Recurring Revenue (ARR)"
            type="text"
            :value="application_data.arr"
            helper_text="This field cannot be edited"
            :helper_text_style="getInputStyle"
            readOnly
          ></base-input>
        </b-col>
        <b-col lg="6" sm="12">
          <base-input
            label="Requested Amount From Capifly"
            type="text"
            :value="application_data.requested"
            helper_text="This field cannot be edited"
            :helper_text_style="getInputStyle"
            readOnly
          ></base-input>
        </b-col>
        <b-col lg="6" sm="12">
          <base-input
            label="Number of Investment Rounds to Date"
            type="text"
            :value="application_data.investments"
            helper_text="This field cannot be edited"
            :helper_text_style="getInputStyle"
            readOnly
          ></base-input>
        </b-col>
        <b-col lg="6" sm="12">
          <base-input
            label="Number of Active Contracts"
            type="text"
            :value="application_data.contracts"
            helper_text="This field cannot be edited"
            :helper_text_style="getInputStyle"
            readOnly
          ></base-input>
        </b-col>
        <b-col lg="6" sm="12">
          <base-input
            label="Shariah Compliant Facility Preference"
            type="text"
            :value="application_data.shariah_compliant ? 'YES' : 'NO'"
            helper_text="This field cannot be edited"
            :helper_text_style="getInputStyle"
            readOnly
          ></base-input>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row class="mt-4">
      <b-col offset-lg="7" lg="5" sm="12" class="d-flex align-items-center">
        <base-button
          type="submit"
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
          @onClick="save"
        >
          SAVE CHANGES
        </base-button>
        <base-button
          type="submit"
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
          @onClick="cancel"
        >
          CANCEL
        </base-button>
      </b-col>
    </b-row>
    <b-modal id="confirm-modal" size="lg" centered hide-header hide-footer>
      <div class="modal-title">
        Are you sure you want to change and save the company details?
      </div>
      <b-row>
        <b-col lg="2" sm="0" />
        <b-col lg="4" sm="6">
          <base-button
            type="submit"
            style_variant="secondary"
            cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
            :loading="loading"
            @onClick="confirmSave"
          >
            YES, SAVE CHANGES
          </base-button>
        </b-col>
        <b-col lg="4" sm="6">
          <base-button
            type="submit"
            style_variant="secondary"
            cus_style="font-size: 15px; font-family: Roboto_Medium"
            :disabled="loading"
            @onClick="cancel"
          >
            CANCEL
          </base-button>
        </b-col>
        <b-col lg="2" sm="0" />
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

import CompanyService from "../../services/company.service";

export default {
  emits: ["close-edit-mode"],
  props: ["application_data"],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      error: null,
      loading: false,
      name: this.application_data.company.name || "",
      company_work_email: this.application_data.company.email || "",
      phone: this.application_data.company.phone || "",
      website: this.application_data.company.website || "",
    };
  },
  methods: {
    setValue(val, field) {
      this[field] = val;
    },
    async save() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      this.$bvModal.show("confirm-modal");
    },
    cancel() {
      this.$emit("close-edit-mode");
    },
    confirmSave() {
      this.loading = true;
      CompanyService.patch(this.application_data.company.id, {
        name: this.name,
        phone: this.phone,
        website: this.website,
      })
        .then((data) => {
          if (!data) {
            this.error = "There was an error updating company.";
          }
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error updating company.";
        })
        .finally(() => {
          this.loading = false;
          this.cancel();
        });
    },
  },
  computed: {
    getInputStyle() {
      return `text-align:right;color:#8F8B8D;font-family:Roboto_Italic;font-size:13px;`;
    },
  },
};
</script>

<style scoped>
#edit-detail-container {
  padding: 1rem;
}
#edit-detail-container .title {
  font-size: 24px;
  color: #260b19;
}
</style>
