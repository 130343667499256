export const ErrorMessages = {
    "fileSize":"The uploaded file is too large. The maximum file size allowed is 5 MB",
    "noFileSelected" :"Please select a file to upload.",
    "missingColums":"csv_file must be at least 4 columns",
    "errorUploading":"Error uploading",
    "noData":"No data found.",
    'errorLoading':"An error occurred. Please try again later.",
    "noApplications":"No applications found.",
    "errorLoadingApplications":"There was an error loading applications.",
    "errorDeleting":"There was an error deleting the application score ",
    "errorSheet":"There was an error getting the sheets",
    "errorFinancialData":"No financial information",
    "errorAnalysisData":"No Analysis information",
    "cantAddScore":"Cant generate a new score try again later",
    "errorDeletingUser":"There was an error deleting the user",
    "errorDownloading":"There was an error downloading the file",
  };
  