<template>
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          aria-current="page"
          :class="{ active: selectedTab === 'stepOne' }"
          @click="selectTab('stepOne')"
        >
          System Settings</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          @click="selectTab('stepTwo')"
          :class="{ active: selectedTab === 'stepTwo' }"
        >
          Users Settings</a
        >
      </li>
    </ul>

    <div v-if="selectedTab === 'stepOne'">
      <Settings />
    </div>
    <div v-else-if="selectedTab === 'stepTwo'">
      <UsersSettings />
    </div>
  </div>
</template>
<script>
import Settings from "./Settings.vue";
import UsersSettings from "./UsersSettings.vue";

export default {
  name: "settings-page",
  components: {
    Settings,
    UsersSettings,
  },
  data() {
    return {
      selectedTab: "stepOne",
    };
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
  mounted() {},
};
</script>
