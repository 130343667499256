class StorageService {
    /**
     * Return the default storage key  or throws an error if
     * not implemented.
     *
     * @returns {String} The default storage key.
     */
    static get key() {
        throw new Error("Resource getter not defined.");
    }
}


export default StorageService;