import Vue from "vue";

class ResourceService {
  /**
   * Return the resource url.
   *
   * @returns {String|Error} The resource url
   */
  static get resource() {
    throw new Error("resource getter not defined.");
  }

  /**
   * Send a GET request.
   *
   * @param {{}} params
   *
   * @returns {Promise<APIResponse<any>|APIError<any>>}
   */
  static query(params = {}) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(this.resource, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Send a POST request.
   *
   * @param {{}} data
   * @param url or slug to be reuqested
   *
   * @returns {Promise<APIResponse<any>|APIError<any>>}
   */
  static post(data, url=null) {
    let Url = url ? `${this.resource}/${url}` : this.resource;
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(Url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Send a GET request.
   *
   * @param {string} slug
   * @param {{}} params
   *
   * @returns {Promise<APIResponse<any>|APIError<any>>}
   */
  static get(slug, params = {}) {
    const url = `${this.resource}/${slug}`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Send a PUT request.
   *
   * @param {string} slug
   * @param {{}} data
   *
   * @returns {Promise<APIResponse<any>|APIError<any>>}
   */
  static put(slug, data) {
    const url = `${this.resource}/${slug}`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .put(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Send a PATCH request.
   *
   * @param {string} slug
   * @param {{}} data
   *
   * @returns {Promise<APIResponse<any>|APIError<any>>}
   */
  static patch(slug, data) {
    const url = `${this.resource}/${slug}`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .patch(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Send a DELETE request.
   *
   * @param {string} slug
   *
   * @returns {Promise<APIResponse<any>|APIError<any>>}
   */
  static delete(slug) {
    const url = `${this.resource}/${slug}`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .delete(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default ResourceService;
