<template>
  <div id="Second-Application-container">
    <div v-if="application_report">
      <b-row style="padding: 0rem 0.5rem">
        <b-col style="padding: 0rem 0.25rem">
          <div class="block" style="background-color: #bab8b9; color: #260b19">
            <div class="label">TOTAL</div>
            <div class="value">{{ application_report.total }}</div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block" style="background-color: #cddce5; color: #234f68">
            <div class="label">GATHERING</div>
            <div class="d-flex align-items-center">
              <img
                src="/images/gathering-icon.png"
                alt="gathering-icon"
                style="margin-right: 0.5rem"
              />
              <div class="value">{{ application_report.gathering }}</div>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block" style="background-color: #dfddd9; color: #5e595c">
            <div class="label">SCREENING</div>
            <div class="d-flex align-items-center">
              <img
                src="/images/screening-icon.png"
                alt="screening-icon"
                style="margin-right: 0.5rem"
              />
              <div class="value">{{ application_report.screening }}</div>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block" style="background-color: #e8dff5; color: #411884">
            <div class="label">QUALIFIED</div>
            <div class="d-flex align-items-center">
              <img
                src="/images/qualified-icon.png"
                alt="qualified-icon"
                style="margin-right: 0.5rem"
              />
              <div class="value">{{ application_report.qualified }}</div>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block" style="background-color: #fae3d4; color: #cc4e00">
            <div class="label">NOT QUALIFIED</div>
            <div class="d-flex align-items-center">
              <img
                src="/images/not-qualified-icon.png"
                alt="not-qualified-icon"
                style="margin-right: 0.5rem"
              />
              <div class="value">{{ application_report.disqualified }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row style="padding: 0rem 0.5rem">
        <b-col style="padding: 0rem 0.25rem">
          <div class="block" style="background-color: #dfddd9; color: #5e595c">
            <div class="label">IN EXECUTION</div>
            <div class="d-flex align-items-center">
              <img
                src="/images/execution-icon.png"
                alt="execution-icon"
                style="margin-right: 0.5rem"
              />
              <div class="value">{{ application_report.execution }}</div>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block" style="background-color: #ebdfe5; color: #741b47">
            <div class="label">FUNDED</div>
            <div class="d-flex align-items-center">
              <img
                src="/images/funded-icon.png"
                alt="funded-icon"
                style="margin-right: 0.5rem"
              />
              <div class="value">{{ application_report.funded }}</div>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block" style="background-color: #c9e2c1; color: #1c9d0b">
            <div class="label">COMPLETED</div>
            <div class="d-flex align-items-center">
              <img
                src="/images/completed-icon.png"
                alt="completed-icon"
                style="margin-right: 0.5rem"
              />
              <div class="value">{{ application_report.completed }}</div>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div
            class="block"
            style="background-color: #260b1919; color: #260b19"
          >
            <div class="label">CANCELED</div>
            <div class="d-flex align-items-center">
              <img
                src="/images/canceled-icon.png"
                alt="canceled-icon"
                style="margin-right: 0.5rem"
              />
              <div class="value">{{ application_report.canceled }}</div>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem"> </b-col>
      </b-row>
    </div>

    <div class="top-container">
      <div class="filter-container">
        <base-input
          placeholder="Search"
          type="text"
          field="term"
          :value="term"
          @change-value="setValue"
          :remove_default_margin="true"
          :cus_style="getSearchBoxStyle"
        ></base-input>

        <div style="position: relative">
          <base-button
            style_variant="primary"
            :cus_style="getFilterButtonStyle"
            @onClick="toggle_filter_menu"
          >
            <img
              src="/images/sort.png"
              alt="sort"
              style="margin-right: 0.5rem; height: 18px; width: 18px"
            />
            <span v-if="this.$route.query.filter">
              {{ this.$route.query.filter.split(",").length }}
              {{
                this.$route.query.filter.split(",").length > 1
                  ? "Filters"
                  : "Filter"
              }}
            </span>
            <span v-else> Filters </span>
          </base-button>
          <common-menu
            custom_style="left: 0px; top: 50px;"
            :open_menu="open_filter_menu"
            :menu_items="filter_menu_items.map((each) => each.label)"
            :selected_item="selected_filter_item"
            @select_item="select_filter_item"
          />
        </div>

        <div style="position: relative">
          <base-button
            style_variant="primary"
            :cus_style="getFilterButtonStyle"
            @onClick="toggle_com_menu"
          >
            <img
              src="/images/sort.png"
              alt="sort"
              style="margin-right: 0.5rem; height: 18px; width: 18px"
            />
            <span
              v-if="
                com_menu_items &&
                com_menu_items.length &&
                this.$route.query.company_id &&
                this.$route.query.company_id.length
              "
            >
              {{
                com_menu_items.find(
                  (each) => each.id === this.$route.query.company_id
                ).name
              }}
            </span>
            <span v-else> All Companies </span>
          </base-button>
          <common-menu
            custom_style="left: 0px; top: 50px;"
            :open_menu="open_com_menu"
            :menu_items="com_menu_items.map((each) => each.name)"
            :selected_item="selected_com_item"
            @select_item="select_com_item"
          />
        </div>
      </div>
      <div class="pagination-container" v-if="total_count">
        <base-pagination
          :active_page="active_page"
          :total_page="total_page"
          :total_count="total_count"
          :page_size="page_size"
          @set-page="setPage"
        />
      </div>
    </div>

    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <div v-if="querying">
      <common-loading />
    </div>
    <b-row style="padding: 0rem 0.25rem" v-else>
      <b-col
        lg="3"
        sm="6"
        xs="6"
        style="padding: 0rem 0.5rem"
        v-for="(each, index) in data"
        :key="index"
      >
        <div
          class="content-block"
          @mouseenter="comMouseEnter(index)"
          @mouseleave="comMouseLeave"
          @click.stop="openDetail(each.id)"
        >
          <div class="d-flex align-items-center">
            <img
              v-if="each.company.image"
              class="com-avatar"
              :src="each.company.image"
              alt="com-logo"
            />
            <div
              v-else
              class="com-avatar d-flex justify-content-center align-items-center"
            >
              {{ each.company.name.charAt(0).toLocaleUpperCase() }}
            </div>
            <div>
                <div class="com-name">{{ each.company.name }}</div>
                <div class="com-place-title">{{  each.name }} </div>
              <div>
                <div class="com-place">{{  each.company.country }} </div>
                <div class="application-date"> {{ new Date(each.created_at).toLocaleString([], {month: 'short', day:'numeric', year:'numeric', hour:'numeric', minute:'numeric'}) }}</div>
              </div>
            </div>
          </div>
          <div class="amount-container">
            <div v-if="each.sale_price">
              <b-progress height="5px" style="width: 100%">
                <b-progress-bar
                  style="background-color: #741b47"
                  :value="each.progress"
                ></b-progress-bar>
              </b-progress>
              <div class="amount-label">
                <span v-html="$amount(each.paid)"></span> /
                <span v-html="$amount(each.sale_price)"></span>
              </div>
            </div>
          </div>
          <div class="discount-label">
            <div>
              <span style="font-family: Roboto_Bold" v-if="each.score"
                >{{ each.score }}.</span
              >
              <span v-if="each.discount">{{ each.discount }}% discount</span>
            </div>
          </div>
          <div class="d-inline-block">
            <div
              class="state"
              :style="`border: 1px solid ${
                each.state === 'SCREENING'
                  ? '#8f8b8d'
                  : each.state === 'GATHERING'
                  ? '#234F68'
                  : each.state === 'FUNDED'
                  ? '#741B47'
                  : each.state === 'QUALIFIED'
                  ? '#411884'
                  : each.state === 'DISQUALIFIED'
                  ? '#CC4E00'
                  : each.state === 'EXECUTION'
                  ? '#8F8B8D'
                  : each.state === 'COMPLETED'
                  ? '#1c9d0b'
                  : each.state === 'CANCELED'
                  ? '#260b19'
                  : ''
              };color: ${
                each.state === 'SCREENING'
                  ? '#8f8b8d'
                  : each.state === 'GATHERING'
                  ? '#234F68'
                  : each.state === 'FUNDED'
                  ? '#741B47'
                  : each.state === 'QUALIFIED'
                  ? '#411884'
                  : each.state === 'DISQUALIFIED'
                  ? '#CC4E00'
                  : each.state === 'EXECUTION'
                  ? '#8F8B8D'
                  : each.state === 'COMPLETED'
                  ? '#1c9d0b'
                  : each.state === 'CANCELED'
                  ? '#260b19'
                  : ''
              };`"
            >
              <img
                v-if="each.state === 'SCREENING'"
                src="/images/screening-icon.png"
                alt="screening-icon"
                style="margin-right: 0.5rem"
              />
              <img
                v-else-if="each.state === 'GATHERING'"
                src="/images/gathering-icon.png"
                alt="gathering-icon"
                style="margin-right: 0.5rem"
              />
              <img
                v-else-if="each.state === 'FUNDED'"
                src="/images/funded-icon.png"
                alt="funded-icon"
                style="margin-right: 0.5rem"
              />
              <img
                v-else-if="each.state === 'QUALIFIED'"
                src="/images/qualified-icon.png"
                alt="qualified-icon"
                style="margin-right: 0.5rem"
              />
              <img
                v-else-if="each.state === 'DISQUALIFIED'"
                src="/images/not-qualified-icon.png"
                alt="not-qualified-icon"
                style="margin-right: 0.5rem"
              />
              <img
                v-else-if="each.state === 'EXECUTION'"
                src="/images/execution-icon.png"
                alt="execution-icon"
                style="margin-right: 0.5rem"
              />
              <img
                v-else-if="each.state === 'COMPLETED'"
                src="/images/completed-icon.png"
                alt="completed-icon"
                style="margin-right: 0.5rem"
              />
              <img
                v-else-if="each.state === 'CANCELED'"
                src="/images/canceled-icon.png"
                alt="canceled-icon"
                style="margin-right: 0.5rem"
              />
              <div style="margin-top: 2px">{{ each.state }}</div>
            </div>
          </div>
          <div
            class="action-container"
            v-if="hoverIndex === index"
            @click.stop="openMenu"
          >
            <img
              :src="
                openMenuFlag ? '/images/eye-icon.png' : '/images/dot-icon.png'
              "
              :alt="openMenuFlag ? 'eye-icon' : 'dot-icon'"
            />
          </div>
          <div class="action-menu" v-if="hoverIndex === index && openMenuFlag">
            <div class="action-menu-item">View</div>
            <div
              class="action-menu-item"
              style="margin-top: 0.5rem"
              @click.stop="openModal(each)"
              v-if="each.state !== 'CANCELED' && each.state !== 'COMPLETED'"
            >
              Move to next stage
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="bottom-container">
      <div class="pagination-container" v-if="total_count">
        <base-pagination
          :active_page="active_page"
          :total_page="total_page"
          :total_count="total_count"
          :page_size="page_size"
          @set-page="setPage"
        />
      </div>
    </div>

    <change-status-modal
      :application_data="application_data"
      @refresh-page="query"
    />
  </div>
</template>

<script>
import CompanyService from "../../services/company.service";
import ApplicationsService from "../../services/applications.service";
import EnumeratesService from "../../services/enumerates.service";
import ReportsService from "../../services/reports.service";

import ChangeStatusModal from "../../components/common/ChangeStatusModal.vue";

export default {
  name: "Second-Application-Page",
  components: { ChangeStatusModal },
  data() {
    return {
      querying: false,
      error: null,
      hoverIndex: null,
      openMenuFlag: false,
      term: "",
      page_size: 8,
      active_page: 1,
      total_page: 0,
      total_count: 0,
      data: [],
      application_data: null,
      filter_menu_items: [
        {
          label: "ALL",
          state: "",
        },
        {
          label: "Gathering",
          state: "GATHERING",
        },
        {
          label: "Screening",
          state: "SCREENING",
        },
        {
          label: "Qualified",
          state: "QUALIFIED",
        },
        {
          label: "Not Qualified",
          state: "DISQUALIFIED",
        },
        {
          label: "In-Execution",
          state: "EXECUTION",
        },
        {
          label: "Funded",
          state: "FUNDED",
        },
        {
          label: "Complete",
          state: "COMPLETED",
        },
        {
          label: "Cancel",
          state: "CANCELED",
        },
      ],
      open_filter_menu: false,
      selected_filter_item: null,
      com_menu_items: [],
      open_com_menu: false,
      selected_com_item: null,
      application_report: null,
    };
  },
  methods: {
    select_filter_item(item) {
      let company_ids =
          this.$route.query &&
          this.$route.query.company_id &&
          this.$route.query.company_id.length
            ? this.$route.query.company_id.split(",")
            : [],
        filters =
          this.$route.query &&
          this.$route.query.filter &&
          this.$route.query.filter.length
            ? this.$route.query.filter.split(",")
            : [],
        queryString = "";

      if (item && item !== "ALL") {
        const rec = this.filter_menu_items.find((each) => each.label === item);

        if (rec) {
          const index = filters.findIndex((each) => each === rec.state);

          if (index >= 0) filters.splice(index, 1);
          else filters.push(rec.state);
        }
      } else {
        filters = [];
      }

      const params = {
        filter: filters.join(","),
        company_id: company_ids.join(","),
      };

      queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");

      this.$router.push(`/applications?${queryString}`);

      this.selected_filter_item = item;
      this.toggle_filter_menu();
    },
    select_com_item(item) {
      let company_ids =
          this.$route.query &&
          this.$route.query.company_id &&
          this.$route.query.company_id.length
            ? this.$route.query.company_id.split(",")
            : [],
        filters =
          this.$route.query &&
          this.$route.query.filter &&
          this.$route.query.filter.length
            ? this.$route.query.filter.split(",")
            : [],
        queryString = "";

      if (item && item !== "ALL") {
        const rec = this.com_menu_items.find((each) => each.name === item);
        if (rec) {
          company_ids = [rec.id];
        }
      } else {
        company_ids = [];
      }

      const params = {
        filter: filters.join(","),
        company_id: company_ids.join(","),
      };

      queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");

      this.$router.push(`/applications?${queryString}`);

      this.selected_com_item = item;
      this.toggle_com_menu();
    },
    comMouseEnter(index) {
      this.hoverIndex = index;
    },
    comMouseLeave() {
      this.hoverIndex = null;
      this.openMenuFlag = false;
    },
    openDetail(id) {
      this.$router.push(`/applications/${id}`);
    },
    openMenu() {
      this.openMenuFlag = !this.openMenuFlag;
    },
    setValue(val, field) {
      this[field] = val;
    },
    setPage(page) {
      this.active_page = page;
    },
    openModal(data) {
      this.application_data = data;
      this.$bvModal.show("change-status-modal");
      this.openMenu();
    },
    toggle_filter_menu() {
      this.open_filter_menu = !this.open_filter_menu;
    },
    toggle_com_menu() {
      this.open_com_menu = !this.open_com_menu;
    },
    query(params = {}) {
      params["page"] = this.active_page || 1;
      params["limit"] = this.page_size;

      if (this.term) params.search = this.term;

      if (this.$route.query.filter) {
        params["state"] = this.$route.query.filter;
      }

      if (this.$route.query.company_id) {
        params["company_id"] = this.$route.query.company_id;
      }

      this.error = null;
      this.querying = true;
      this.data = [];
      this.total_count = 0;

      ApplicationsService.query(params)
        .then(({ count, objects }) => {
          if (!count) {
            this.error = "No applications found.";
          } else {
            this.total_count = count;
            this.data = objects.map((item) => {
              if (item.company.country && this.countries.length)
                item.company.country = this.countries.find(
                  (each) => each.id === item.company.country
                ).name;

              return item;
            });
          }
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading applications.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
    report() {
      this.querying = true;

      ReportsService.applications()
        .then((data) => {
          this.application_report = data;
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading applications report.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
  },
  computed: {
    getSearchBoxStyle() {
      return `margin-right: 1rem;
      border: 1px solid #dfddd9;
      border-radius: 10px;
      background-color: transparent;
      `;
    },
    getFilterButtonStyle() {
      return `font-size: 15px;
      border-radius: 10px;
      border: 1px solid #dfddd9;
      background-color: transparent;
      color:#8F8B8D;
      margin-right:1rem;`;
    },
  },
  watch: {
    total_count(val) {
      this.total_page = Math.ceil(val / this.page_size);
    },
    term() {
      this.active_page = 1;
      this.query();
    },
    active_page() {
      this.query();
    },
    $route(val) {
      if (val) {
        this.query();
      }
    },
  },
  mounted() {
    this.querying = true;
    EnumeratesService.countries()
      .then((data) => {
        if (data && data.length)
          this.countries = data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        CompanyService.query()
          .then(({ objects }) => {
            this.com_menu_items = objects.filter(
              (each) => each.state === "ACCEPTED"
            );
          })
          .catch(({ message }) => {
            this.error = message;
          })
          .finally(() => {
            this.com_menu_items.reverse();
            this.com_menu_items.unshift({ id: "ALL", name: "ALL" });
            this.querying = false;
            this.query();
            this.report();
          });
      });
  },
};
</script>

<style scoped>
#Second-Application-container {
  padding: 1rem 0rem;
}
#Second-Application-container .title {
  font-size: 18px;
  font-family: Roboto_Medium;
  color: #8f8b8d;
  margin-bottom: 1rem;
}
#Second-Application-container .block {
  padding: 10px 15px;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}
#Second-Application-container .label {
  font-size: 14px;
  font-family: Roboto_Medium;
}
#Second-Application-container .value {
  font-size: 16px;
  font-family: Roboto_Bold;
}
#Second-Application-container .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.5rem 0rem 1.5rem;
}
#Second-Application-container .filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#Second-Application-container .content-block {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 15px 20px;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;
}
#Second-Application-container .content-block:hover {
  box-shadow: 1px 2px 8px #17172429;
}
#Second-Application-container .com-avatar {
  margin-right: 0.5rem;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ebdfe5;
  color: #260b19;
  font-size: 14px;
  font-family: Roboto_Medium;
}
#Second-Application-container .com-avatar img {
  border-radius: 50%;
}
#Second-Application-container .com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
  color: #260b19;
}
#Second-Application-container .com-place {
  font-size: 13px;
  color: #8f8b8d;
  float:left;
}

#Second-Application-container .com-place-title {
  font-size: 13px;
  color: #8f8b8d;
  float:none;
}
#Second-Application-container .amount-container {
  height: 4.5rem;
  display: flex;
  align-items: center;
}
#Second-Application-container .amount-label {
  font-size: 13px;
  font-family: Roboto_Medium;
  color: #741b47;
}
#Second-Application-container .discount-label {
  height: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #411884;
}
#Second-Application-container .state {
  padding: 0.1rem 0.3rem;
  font-size: 11px;
  font-family: Roboto_Bold;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Second-Application-container .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
#Second-Application-container .action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
#Second-Application-container .action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  right: 0.5rem;
  top: 2.7rem;
  z-index: 99999;
}
#Second-Application-container .action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}

.application-date{
  float:left;
  margin-left:7px;
  padding:0px 3px;
  font-family: Roboto_Medium;
  font-size:11px;
  margin-top:1px;

  border-radius: 5px;
  border:1px solid lightblue;
  background-color: #8379d5;
  color: #fbf0f0;
}
</style>
