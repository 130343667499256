<template>
    <div>
        <p v-if="errorMessage && !loading" class="error-block">{{ errorMessage }}</p>
        <div v-if="loading">
            <b-row>
                <b-col lg="6" sm="12">
                    <base-input label="Upload File" :placeholder="'Upload your file here'" ref="fileInput" type="file"
                        class="ibra" accept=".csv" :value="uploadedFile ? uploadedFile.name : ''"
                        @change-value="handleFileUpload">
                    </base-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <base-select label="Industry" :options="IndustryOptions" field="industry" :value="selectedIndustry"
                        @change-value="setIndustry"></base-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <base-select label="Calculation Method" :options="calculationMethods" field="selectedMethod"
                        :value="selectedMethod" @change-value="setSelectedMethod"></base-select>
                </b-col>
            </b-row>
            <p v-if="errorMessage" class="error-block">{{ errorMessage }}</p>

        </div>
        <div v-if="formShow">
            <div v-if="!errorMessage" class="demo">
                <div>
                    <div class="key-data" v-for="(item, index) in dataKey" :key="index">
                        {{ orderKeys.includes(item.id) ? item.id : "" }} <span class="key-details"
                            v-if="DATA_KEY_DETAILS[item.id]">{{
                                DATA_KEY_DETAILS[item.id] }}:</span>
                    </div>
                </div>
                <div class="columns">
                    <div v-for="(items, index) in groups" :key="index" class="column values-data">
                        <Container :data-index="index" group-name="column"
                            :get-child-payload="(itemIndex) => getChildPayload(index, itemIndex)" :should-accept-drop="(src, payload) => getShouldAcceptDrop(index, src, payload)
                                " :should-animate-drop="(src, payload) => getShouldAnimateDrop(index, src, payload)
        " @drop="onDrop(index, $event)">
                            <Draggable v-for="item in items" :key="item.id">
                                <div class="draggable-item dragabble-custom" title="Drag to reorder">
                                    {{ item.data }}
                                </div>
                            </Draggable>
                        </Container>

                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="buttons-block">
                <base-button style_variant="primary"
                    cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;width:150px"
                    @onClick="loading ? handleCsvFileUpload() : handleSubmitMappingData()"
                    :disabled="errorMessage || disabledUpload || isDropdownNotSelected" :loading="loadingBtn">
                    SUBMIT
                </base-button>
                <base-button style_variant="secondary" cus_style="font-size: 15px; font-family: Roboto_Medium;width:150px"
                    @onClick="handleClose">
                    CANCEL
                </base-button>
            </div>
        </div>


    </div>
</template>

<script>
import { Container, Draggable } from "vue-dndrop";
import Vue from "vue";
import ApplicationsService from "../services/applications.service";
import { ErrorMessages } from "@/lib/errorMessages";

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;
    const result = [...arr];
    let itemToAdd = payload;
    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
};

const keyOrder = ["customer_id", "amount", "start_date", "period"]

export default {
    name: "ApplicationFileUploadForm",
    components: {
        Container,
        Draggable,
    },
    props: ["application_id"],
    data() {
        return {
            orderKeys: ["customer_id", "amount", "start_date", "period"],
            IndustryOptions: [{ id: null, label: "Select Industry" }, { id: "Saas", label: "Saas" }, { id: "Content", label: "Content" }, { id: "Gaming", label: "Gaming" }, { id: "Marketplace", label: "Marketplace" }],
            DATA_KEY_DETAILS: {
                "customer_id": "customer_id details",
                "amount": " amount details",
                "start_date": "start_date ",
                "period": " period details",
            },
            groups: [],
            flags: [],
            formShow: false,
            loading: true,
            errorMessage: null,
            uploadedFile: null,
            loadingBtn: false,
            disabledUpload: true,
            selectedIndustry: null,
            dataKey: [],
            logs: {
                "get-child-payload": true,
                "should-accept-drop": false,
                "should-animate-drop": false,
                "drag-start": true,
                "drag-end": true,
                "drag-enter": true,
                "drag-leave": true,
                "drop-not-allowed": true,
                drop: true,
            },
            logPayload: true,
            sassMethods: [{ id: null, label: "Select Calculation method" },
            { id: "Forward", label: "No Refund/Forward deduction" }, { id: "Backward", label: "Backward deduction" }, { id: "SameMonth", label: "Same Month deduction" }],
            selectedMethod: null,
            columnNames: []
        };
    },
    created() {
        this.addColumn();
    },
    watch: {
        groups(newValue, oldValue) {
            this.groups = newValue
        },
    },
    methods: {
        async handleFileUpload(event) {
            this.errorMessage = null;
            if (event.size > 5000000) {
                this.errorMessage = ErrorMessages.fileSize;
                return;
            }
            this.uploadedFile = event;
            this.disabledUpload = false;

            const reader = new FileReader();
            reader.onload = (e) => {
                const csvContent = e.target.result;
                this.columnNames = this.parseCSVColumnNames(csvContent);
            };
            reader.readAsText(event);
        },
        parseCSVColumnNames(csvContent) {
            const lines = csvContent.split(/\r?\n/);
            const firstLine = lines[0];
            if (!firstLine) {
                this.errorMessage = 'The CSV file is empty.';
                return [];
            }
            const columnNames = firstLine.split(',');
            const removeEmptyColumn = columnNames.map((name) => name.trim()).filter(Boolean)
            return removeEmptyColumn;
        },
        handleClose() {
            this.$bvModal.hide("ApplicationFileUploadModal");
        },
        setIndustry(indusry) {
            this.selectedIndustry = indusry
            this.selectedMethod = null
        },
        setSelectedMethod(method) {
            this.selectedMethod = method
        },
        async handleCsvFileUpload() {
            if (!this.uploadedFile) {
                this.errorMessage = ErrorMessages.noFileSelected;
                return;
            }
            this.loadingBtn = true
            if (this.columnNames.length < 4) {
                this.loading = true;
                this.formShow = false
                this.loadingBtn = false
                this.errorMessage = ErrorMessages.missingColums
                return
            }
            const tempResult = []

            for (let i = 0; i < this.columnNames.length; i++) {
                tempResult.push({ id: keyOrder[i] ?? this.columnNames[i] + i, data: this.columnNames[i] ?? "" })
            }
            this.dataKey = tempResult
            this.groups = []
            this.groups.push(tempResult)
            this.loadingBtn = false
            this.loading = false;
            this.formShow = true;
        },

        async handleSubmitMappingData() {
            this.loadingBtn = true
            if (!this.selectedIndustry) {
                this.errorMessage = "Please select an Industry";
                return;
            }

            const tempResult = {}
            for (let i = 0; i < keyOrder.length; i++) {
                tempResult[keyOrder[i]] = this.groups[0][i].data
            }
            const formatCsv = await toBase64(this.uploadedFile)

            ApplicationsService.uploadTransactionalData(
                this.application_id,
                {
                    "col_mapping": tempResult,
                    "csv_file": formatCsv,
                    "industry": this.selectedIndustry,
                    "calculation_method": this.selectedMethod
                }
            ).then((res) => {
                this.loadingBtn = false;
                this.$bvModal.hide("ApplicationFileUploadModal");
                this.$router.push("/ApplicationDetails");
            }).catch((err) => {
                this.errorMessage = err?.errors?.message;
                this.loading = true;
                this.formShow = false
                this.loadingBtn = false
            })
        },
        getChildPayload(groupIndex, itemIndex) {
            return this.groups[groupIndex][itemIndex];
        },
        getShouldAcceptDrop(index, sourceContainerOptions, payload) {
            return true;
        },
        getShouldAnimateDrop(index, sourceContainerOptions, payload) {
            return true;
        },
        onDrop(groupIndex, dropResult) {
            let result = applyDrag(this.groups[groupIndex], dropResult);
            Vue.set(this.groups, groupIndex, result);
        },
        addColumn() {
            this.flags.push({ drop: true, animate: true });
        },
        setValue(val, field) {
            this[field] = val;
        },

    },
    computed: {
        isDropdownNotSelected() {
            return !this.selectedIndustry || !this.selectedMethod;
        },
        calculationMethods() {
            return this.selectedIndustry === "Saas" ? this.sassMethods : [{ id: null, label: "Select Calculation method" }, { id: "Standard", label: 'Standard' }];
        },
    }
};
</script>

<style scoped>
.dragabble-custom {
    cursor: move;
}

.menu-title {
    font-size: 15px;
    color: #5e595c;
    margin-bottom: 5px;
}

.buttons-block {
    display: flex;
    justify-content: flex-end;
}

.values-data {
    font-size: 20px;
}

.key-details {
    font-size: 15px;
    color: gray;
}

.key-data {
    font-size: 20px;
    font-weight: 800;
}

.demo {
    display: flex;
    gap: 75px;
    margin-bottom: 16px;
}

.controls {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
}

.controls .title {
    align-self: flex-start;
    margin: 0 1rem;
}

.controls .buttons {
    align-self: flex-end;
    margin: 1rem;
}

.controls .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 1rem;
}

.controls>div {
    padding-top: 1em;
}

.buttons,
.column-actions {
    display: flex;
    gap: 1rem;
}

.column-actions {
    justify-content: space-evenly;
}

.buttons .button-column {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-left: 5px solid #c4ebaf;
    border-radius: 4px;
    padding: 0.5rem;
    cursor: pointer;
    transition: border-color 0.2s linear;
    font-family: inherit;
}

.buttons .button-column.remove {
    border-left: 5px solid #dc3545;
}

.buttons .button-column.remove:disabled {
    border-left: 5px solid #e0e0e0;
}

.buttons .button-column.add {
    border-left: 5px solid #c4ebaf;
}

label {
    display: block;
    line-height: 1.6em;
}

.columns {
    display: flex;
    gap: 0.5rem;
    justify-content: stretch;
}

.column {
    flex: 1;
    border-radius: 6px;
    /* border: 1px solid #e0e0e0; */
}

.column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.column .dndrop-container.vertical {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    cursor: pointer;
}

.error-block {
    color: #721c24;
    font-size: 12px;
    font-weight: 700;
}
</style>