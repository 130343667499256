<template>
  <div id="user-setting">
    <div class="top-container">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>

      <div class="pagination-container" v-if="total_count">
        <base-pagination :active_page="active_page" :total_page="total_page" :total_count="total_count"
          :page_size="page_size" @set-page="setPage" />
      </div>
    </div>
    <div v-if="querying">
      <common-loading />
    </div>
    <div class="content-table" v-if="data && data.length > 0">
      <div class="table-container">
        <b-row class="table-title">
          <b-col class="table-title-field">
            <div class="table-title-text">Company Name</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Email</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">First Name</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">Last Name</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </b-col>

          <div class="table-title-field notify">
            <div class="table-title-text">Notify</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </div>
          <div class="table-title-field notify">
            <div class="table-title-text">Actions</div>
            <img class="sort-icon" src="/images/sort-icon.png" alt="sort-icon" />
          </div>


        </b-row>
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <div v-if="querying">
          <common-loading />
        </div>
        <b-row class="table-data" v-for="(each, index) in data" :key="index">
          <b-col class="com-info">
            <div class="com-name">
              {{
                each.company_name
              }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country">
              {{
                each.email

              }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country">
              {{
                each.first_name
              }}
            </div>
          </b-col>
          <b-col>
            <div class="com-country">
              {{
                each.last_name
              }}
            </div>
          </b-col>
          <div class="notify">
            <div class="com-country">
              <div class=" form-check form-switch">
                <input class="form-check-input " type="checkbox" v-model="each.notify" @change="handleNotify(each)"
                  :checked="each.notify">
              </div>
            </div>
          </div>
          <div class="notify">
            <div class="com-country position-rel">
              <div class="action-container" @click.stop="openMenu(index)">
                <img :src="openMenuFlag === index
                  ? '/images/eye-icon.png'
                  : '/images/dot-icon.png'
                  " :alt="openMenuFlag === index ? 'eye-icon' : 'dot-icon'" />
              </div>
              <div class="action-menu" v-if="openMenuFlag === index">
                <div class=" action-menu-item" @click="handleChangePassword(each)">
                  Edit
                </div>
                <div class=" action-menu-item" @click="handleDelete(each.id)">
                  Delete
                </div>

              </div>
            </div>
          </div>
        </b-row>
        <div>
          <base-button style_variant="primary" cus_style="font-size:15px" @onClick="handleAddUser">
            Add User
          </base-button>
        </div>

      </div>
    </div>
    <div class="bottom-container">
      <div class="pagination-container" v-if="total_count">
        <base-pagination :active_page="active_page" :total_page="total_page" :total_count="total_count"
          :page_size="page_size" @set-page="setPage" />
      </div>
    </div>
    <AddUserModal @refresh-page="query" />
    <div v-if="modalData">
      <EditModal :data="modalData" @refresh-page="query" />
    </div>
    <ConfirmationDialog :onSubmit="handleSoftDelete" text="Are you sure you want to delete this user?" />
  </div>
</template>

<script>
import SettingsService from "@/services/settingsDashboard.service";
import { ErrorMessages } from "@/lib/errorMessages";
import AddUserModal from "@/components/common/AddUserModal.vue"
import EditModal from "@/components/common/EditModal.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";

export default {
  name: "user-setting",
  components: { AddUserModal, EditModal, ConfirmationDialog },
  data() {
    return {
      error: null,
      querying: false,
      page_size: 10,
      active_page: 1,
      total_page: 0,
      total_count: 0,
      data: [],
      modalData: null,
      openMenuFlag: false,
      deleteId: null,

    };
  },
  methods: {
    handleDelete(id) {
      this.deleteId = id;
      this.$bvModal.show("confirmation-modal");

    },
    openMenu(index) {
      this.openMenuFlag = this.openMenuFlag === index ? null : index;
    },
    handleChangePassword(data) {
      this.modalData = data
      this.$bvModal.show("edit-modal");
    },
    handleNotify(data) {
      const payload = {
        id: data.id,
        notify: data.notify,
      }
      SettingsService.updateUser(payload).then((res) => {

      }).catch((e) => {
        this.error = ErrorMessages.errorUploading
      })
    },
    setPage(page) {
      this.querying = true;
      this.active_page = page;
      this.updateFilteredData();
      this.querying = false;
    },
    handleAddUser() {
      this.$bvModal.show("Add-User-Modal");

    },
    handleSoftDelete() {
      SettingsService.userSoftDelete(this.deleteId)
        .then(() => {
          this.query()
        })
        .catch(() => {
          this.error = ErrorMessages.errorDeletingUser;
        })
        .finally(() => {
          this.querying = false;
        });
    },
    query(params = {}) {
      params["page"] = this.active_page || 1;
      params["limit"] = this.page_size;

      this.error = null;
      this.querying = true;
      this.data = [];
      this.total_count = 0;

      SettingsService.usersData(params)
        .then(({ data }) => {
          if (!data) {
            this.error = ErrorMessages.noData;
          } else {
            this.total_count = data.count;
            this.data = data?.data.map((item) => {
              return {
                id: item.id,
                email: item.email,
                company_name: item.company_name,
                first_name: item.first_name,
                last_name: item.last_name,
                notify: item.notify,
                is_active: item.is_active,

              };
            });
            this.updateFilteredData();
            this.total_page = Math.ceil(
              this.filteredData.length / this.page_size
            );
          }
        })
        .catch(({ message }) => {
          this.error = message ? message : ErrorMessages.errorLoading;
        })
        .finally(() => {
          this.querying = false;
        });
    },
    updateFilteredData() {
      const start = (this.active_page - 1) * this.page_size;
      const end = start + this.page_size;
      this.filteredData = this.data.slice(start, end);
    },
  },
  watch: {
    total_count(val) {
      this.total_page = Math.ceil(val / this.page_size);
    },
    active_page() {
      this.query();
    },
    $route(val) {
      if (val) this.query();
    },
  },
  mounted() {
    this.querying = true;
    this.query();
    this.querying = false;
  },
};
</script>

<style scoped>
.notify{
  width:70px !important;
}
.action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  z-index: 99999;
}

.action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}

.position-rel {
  position: relative;
}

.com-delete {
  color: #741b47;
  font-size: 13px;
  cursor: pointer;
}

.cant-delete {
  font-size: 13px;
  color: #8f8b8d;
  cursor: not-allowed
}

.action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  z-index: 99999;
}

.action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}

.action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept {
  color: rgb(102, 170, 34);
}

.declined {
  color: #741b47;
}

.delete {
  color: #741b47;
}

.refresh-btn {
  border-radius: 6px;
  text-transform: capitalize;
  padding: 10px 20px;
  width: -webkit-fill-available;
  cursor: pointer;
  font-family: Roboto_Regular;
  background-color: #741b47;
  color: #ffe599;
  border: 1px solid #741b47;
}

#user-setting {
  padding: 1rem 1rem;
}

#user-setting .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}

#user-setting .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

#user-setting .table-container {
  padding: 0.5rem;
}

#user-setting .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

#user-setting .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}

#user-setting .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}

#user-setting .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}

#user-setting .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

#user-setting .com-info {
  display: flex;
  align-items: center;
}

#user-setting .com-name {
  font-size: 15px;
  font-family: Roboto_Bold;
}

#user-setting .com-country {
  font-size: 13px;
  color: #8f8b8d;
}

#user-setting .link-s3 {
  font-size: 13px;
  color: blue;
  font-weight: 600;
}

#user-setting .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
</style>
