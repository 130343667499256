<template>
  <b-modal
    id="Add-User-Modal"
    size="lg"
    centered
    hide-header
    hide-footer
    @hidden="resetForm"
  >
    <div v-if="loading" class="loader-overlay">
      <div
        id="loading-container"
        class="p-5 d-flex justify-content-center align-items-center"
      >
        <b-spinner></b-spinner>
      </div>
    </div>
    <div class="modal-title">AddUserModal</div>
    <div class="settings-wrapper">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <validation-observer ref="observerAddUser">
        <b-row>
          <b-col lg="12" sm="12" class="py-1">
            <ValidationProvider
              rules="required|email"
              name="email"
              v-slot="{ errors }"
            >
              <base-input
                label="Email"
                type="text"
                field="email"
                placeholder="Email"
                :value="formFields.email"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </ValidationProvider>
          </b-col>

          <b-col lg="12" sm="12" class="py-1">
            <ValidationProvider
              rules="required"
              name="first name"
              v-slot="{ errors }"
            >
              <base-input
                label="First name"
                type="text"
                field="firstName"
                placeholder="First name"
                :value="formFields.firstName"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12" class="py-1">
            <ValidationProvider
              rules="required"
              name="last name"
              v-slot="{ errors }"
            >
              <base-input
                label="Last name"
                type="text"
                field="lastName"
                placeholder="Last name"
                :value="formFields.lastName"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12" class="py-1">
            <validation-provider
              name="Company"
              rules="required"
              v-slot="{ errors }"
            >
              <base-select
                label="Company"
                :options="companiesOptions"
                field="company_id"
                :value="formFields.company_id"
                @change-value="setValue"
                class="m-0"
              ></base-select>
              <div class="helper-text" v-if="errors && errors.length">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </b-col>

          <b-col lg="12" sm="12" class="py-1">
            <div class="form-check form-switch">
              <label class="form-label labelText">Password</label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="formFields.isPassword"
                :checked="formFields.isPassword"
                @change="togglePasswordValidation"
              />
            </div>
            <ValidationProvider
              :rules="passwordValidationRules"
              name="password"
              v-slot="{ errors }"
            >
              <base-input
                type="text"
                field="password"
                :disabled="formFields.isPassword"
                :value="formFields.password"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12" class="py-1">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-check form-switch">
                <label class="form-label labelText">Notify</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="formFields.notify"
                  :checked="formFields.notify"
                />
              </div>
              <div class="helper-text" v-if="errors && errors.length">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </b-col>

          <b-col lg="12" sm="12" class="py-1">
            <base-button
              :style_variant="isSubmitDisabled?'secondary':'primary'"
              cus_style="font-size:15px"
              @onClick="submitForm"
              :disabled="isSubmitDisabled"
            >
              SUBMIT
            </base-button>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    <ConfirmationDialog
      :onSubmit="onSubmitDialog"
      text="A user with this email already exists. Do you want to overwrite it with new information?"
    />
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import SettingsService from "@/services/settingsDashboard.service";
import { ErrorMessages } from "@/lib/errorMessages";
import ConfirmationDialog from "@/components/common/ConfirmationDialog";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ConfirmationDialog,
  },
  emits: ["refresh-page"],
  data() {
    return {
      companiesOptions: [
        {
          id: null,
          label: "Select Company ",
        },
      ],
      errors: null,
      error: null,
      loading: false,
      formFields: {
        email: null,
        firstName: null,
        lastName: null,
        company_id: null,
        password: null,
        notify: false,
        isPassword: true,
      },
    };
  },
  methods: {
    resetForm() {
      this.formFields = {
        email: null,
        firstName: null,
        lastName: null,
        company_id: null,
        password: null,
        notify: false,
        isPassword: true,
      };
      this.error = null;
      this.errors = null;
    },
    setValue(val, field) {
      this.formFields[field] = val;
    },
    async onSubmitDialog() {
      try {
        this.loading = true;
        await SettingsService.addUsers(this.formFields);
        this.$emit("refresh-page");
        this.$bvModal.hide("Add-User-Modal");
      } catch (error) {
        this.error = ErrorMessages.errorLoading;
      } finally {
        this.loading = false;
      }
    },
    async submitForm() {
      const isValid = await this.$refs.observerAddUser.validate();
      if (!isValid) return;

      const params = {
        email: this.formFields.email,
      };
      let checkUserExist = await SettingsService.checkUser(params);

      if (checkUserExist?.exists) {
        this.$bvModal.show("confirmation-modal");
        return;
      }

      try {
        this.loading = true;
        await SettingsService.addUsers(this.formFields);
        this.$emit("refresh-page");
        this.$bvModal.hide("Add-User-Modal");
      } catch (error) {
        this.error = ErrorMessages.errorLoading;
      } finally {
        this.loading = false;
      }
    },
    getCompanyOptions() {
      this.loading = true;
      SettingsService.companyLists()
        .then((data) => {
          data?.objects?.map((item) => {
            let temp = { id: item.id, label: item.name };
            this.companiesOptions.push(temp);
          });
        })
        .catch((e) => {
          this.error = ErrorMessages.errorLoading;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    togglePasswordValidation() {
      this.formFields.password = null;
    },
  },
  computed: {
    isSubmitDisabled() {
      const { email, firstName, lastName, company_id, isPassword, password } =
        this.formFields;

      return (
        !email ||
        !firstName ||
        !lastName ||
        !company_id ||
        (!isPassword && !password)
      );
    },
    passwordValidationRules() {
      return this.formFields.isPassword ? "" : "required|min:8";
    },
  },
  mounted() {
    this.getCompanyOptions();
  },
};
</script>

<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}
#Add-User-Modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 1rem;
}

.wrapper {
  padding: 80px;
}

.labelText {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}

.helper-text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.form-control:focus-visible {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:hover {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
</style>
