<template>
  <b-modal
    id="accept-company-modal"
    size="md"
    centered
    hide-header
    hide-footer
    @hidden="reset"
  >
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>

    <b-row>
      <b-col><div class="modal-text">Is this company eligible?</div></b-col>
    </b-row>

    <b-row>
      <b-col md="8" offset-md="2" class="d-flex align-items-center">
        <base-button
          style_variant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
          :loading="loading"
          :disabled="loading"
          @onClick="submit"
        >
          SUBMIT
        </base-button>
        <base-button
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
          :disabled="loading"
          @onClick="closeModal"
        >
          CANCEL
        </base-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import CompanyService from "../../services/company.service";

export default {
  name: "ChangeStatusModal",
  props: ["company_id"],
  emits: ["refresh-page"],
  data() {
    return { error: null, loading: false };
  },
  methods: {
    setValue(val, field) {
      this[field] = val;
    },
    submit() {
      this.loading = true;

      CompanyService.accept(this.company_id)
        .then(() => {
          this.$emit("refresh-page");
          this.closeModal();
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error accepting company.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$bvModal.hide("accept-company-modal");
      this.reset();
    },
    reset() {
      this.error = null;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 3rem 1rem;
}
#accept-company-modal .modal-text {
  font-size: 24px;
  font-family: Roboto_Regular;
  color: #5e595c;
  margin-bottom: 1rem;
  text-align: center;
}
</style>
