//ARR
export function get_record(cohorts_data, d_each, p_each, logos=false) {
  let rec = cohorts_data.find(
              (rec) => rec.date == d_each && rec.period == p_each
            );
  if (rec.arr == 0)
    return ``;

  let ref_rec_data = cohorts_data.find(
      (rec) => rec.date == d_each && rec.period == 1
  );    

  if (rec && rec.value !== null) {
    const val = logos ? parseInt(rec.logos, 10) : parseInt(rec.value, 10);
    if (!logos)
      return `<span>${rec.value}%</span>`;
    else
      return `<span>${rec.logos, ref_rec_data.logos!=0 ? Math.round(100.0 * rec.logos/ref_rec_data.logos) : 0}%</span>`;
  } else return null;
} 


export function get_record_color(cohorts_data, d_each, p_each, logos=false) {

  let rec = cohorts_data.find(
            (rec) => rec.date == d_each && rec.period == p_each
          )
  if (rec && rec.value !== null && rec.arr == 0) return "#f5f3ef !important";

  if (rec && rec.value !== null) {
    let ref_rec = cohorts_data.find((rec) => rec.date == d_each && rec.period == 1)
    if (ref_rec ) {
      let val = logos ? 100.0 * rec.logos/ref_rec.logos : parseInt(rec.value, 10);
      const color = val > 130
          ? "#43872A"
          : val > 110
          ? "#59B438"
          : val > 100
          ? "#BDE4B0"
          : val > 95
          ? "#DAE182"
          : val > 85
          ? "#F9EA84"
          : val > 75
          ? "#FBAE00"
          : val > 65
          ? "#FB7700"
          : val > 51
          ? "#F8696B"
          : "#D01F21";
        return color;  
    }
  } else return null;
}

