<template>
  <div class="wrapper">
    <h3 class="text-center">Settings</h3>
    
    <div class="settings-wrapper">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
      <validation-observer ref="observer">
        <b-row>
          <b-col lg="12" sm="12" class="py-4">
            <ValidationProvider
              :rules="{ regex: /^[0-9]+(\.[0-9]+)?$/ }"
              v-slot="{ errors }"
            >
              <label class="form-label labelText" for="raised"
                >Facility Raised</label
              >
              <input
                type="text"
                v-model="raised"
                class="form-control"
                placeholder="Facility Raised"
                field="days"
              />
              <div class="helper-text" v-if="errors && errors.length">
                This felid accept numbers only
              </div>
            </ValidationProvider>
          </b-col>

          <b-col lg="12" sm="12">
            <base-button
              style_variant="primary"
              cus_style="font-size:15px"
              @onClick="submit"
            >
              SUBMIT
            </base-button>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import SettingsService from "@/services/settingsDashboard.service";
import { ErrorMessages } from "@/lib/errorMessages";
export default {
  name: "settings",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      raised: null,
      errors: null,
      error: null,
    };
  },
  methods: {
    updateFacilityRaised() {
      SettingsService.updateFacilityRaised(this.raised)
        .then((data) => {
          this.raised = data.raised_value;
        })
        .catch(({ message }) => {
          this.error = ErrorMessages.errorUploading;
        });
    },
    settings() {
      SettingsService.settings()
        .then((data) => {
          this.raised = data?.raised_value;
        })
        .catch(({ message }) => {
          this.error = ErrorMessages.errorLoading;
        });
    },
    async submit() {
      // TODO: ADD overlay loading
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      this.updateFacilityRaised();
    },
  },
  mounted() {
    this.settings();
  },
};
</script>
<style scoped>
.settings-wrapper {
  padding: 50px;
  background-color: #ffff;
  border-radius: 20px;
}
.wrapper {
  padding: 80px;
}
.labelText {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}
.helper-text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.form-control:focus-visible {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
.form-control:hover {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
.form-control:focus {
  border-color: #741b47;
  outline: none;
  box-shadow: none;
}
</style>
